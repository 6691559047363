import React from "react";
import { usePostsContext } from "../../../../../context/posts";
import { OfferStatus } from "../../../../../types/offers";
import { useAcceptOffer } from "../../../../Offers/hooks/useAcceptOffer";
import { useCancelOffer } from "../../../../Offers/hooks/useCancelOffer";
import { useDeclineOffer } from "../../../../Offers/hooks/useDeclineOffer";
import { OfferRow } from "../../../../Offers/types/table-models";
import { useConversionRate } from "../../../../Payments/hooks/useConversionRate";
import useToggleModal from "../../../hooks/useToggleModal";
import { Button, CircularProgress } from "@mui/material";
import ModalHeader from "../Header";
import { styles } from "./styles";
import { useUsersContext } from "../../../../../context/users";
import { PER_PAGE } from "../../../../../config";
import { PostStatus } from "../../../../../types/posts";
import { amountToBTC, formatCurrencyAmount } from "../../../../../utils/lib";
import { Currency } from "../../../../../types/common";

interface OfferProps {
  post: OfferRow;
}

function Offer({ post }: OfferProps) {
  const { doAcceptOffer, isLoading: isLoadingAccept } = useAcceptOffer();
  const { doDeclineOffer, isLoading: isLoadingDecline } = useDeclineOffer();
  const { doCancelOffer, isLoading: isLoadingCancel } = useCancelOffer();
  const { btcToUsd } = useConversionRate();
  const { state } = useUsersContext();
  const { refetchPosts } = usePostsContext();
  const { toggle } = useToggleModal();

  const postsCallback = () => {
    refetchPosts({ user: state.user?.sub, limit: PER_PAGE * 2, page: 0, status: PostStatus.OPEN });
  };

  const handleAcceptOffer = () => {
    doAcceptOffer(post._id, post.offer._id, postsCallback);
    toggle(null);
  };
  const handleDeclineOffer = () => {
    doDeclineOffer(post._id, post.offer._id, postsCallback);
    toggle(null);
  };

  const handleCancelOffer = () => {
    doCancelOffer(post._id, post.offer._id, postsCallback);
    toggle(null);
  };

  return (
    <div style={styles.container}>
      <ModalHeader post={post} model="Offer" status={post.offer.status} />
      <div style={styles.row}>
        <p>
          <b style={styles.meta}>Listed For:</b>
          <span>${post.price}</span>
        </p>
        <p>
          <b style={styles.meta}>Listed On:</b>
          <span>{new Date(post.createdAt).toDateString()}</span>
        </p>
      </div>
      <div style={{ ...styles.offer, ...styles.price }}>
        <h3 style={styles.meta}>Offer:</h3>
        {formatCurrencyAmount(post.offer.amount, Currency.USD)} ({amountToBTC(post.offer.amount, btcToUsd)})
      </div>

      {/* Offer receiver buttons */}
      {post.offer.status === OfferStatus.PENDING && post.offer.author.email !== state.user?.email && (
        <div style={styles.buttons}>
          {isLoadingAccept || isLoadingDecline ? (
            <CircularProgress />
          ) : (
            <>
              <Button onClick={handleAcceptOffer} variant="contained">
                Accept
              </Button>

              <Button onClick={handleDeclineOffer} style={styles.decline} variant="outlined">
                Decline
              </Button>
            </>
          )}
        </div>
      )}

      {/* Offer sender button */}
      {post.offer.status === OfferStatus.PENDING &&
        post.offer.author.email === state.user?.email &&
        (isLoadingCancel ? (
          <CircularProgress />
        ) : (
          <div style={styles.buttons}>
            <Button onClick={() => handleCancelOffer()} variant="contained">
              Cancel Offer
            </Button>
          </div>
        ))}
    </div>
  );
}

export default React.memo(Offer);
