import { theme } from "../../../../../theme";

export const styles = {
  container: {
    color: theme.colors.white,
    margin: "auto",
    marginTop: 20,
    padding: theme.padding.xl,
    backgroundColor: theme.colors.secondary,
    borderRadius: theme.borderRadius.l,
  },
  input: {
    backgroundColor: theme.colors.white,
  },
  header: {
    fontSize: theme.fontSize.s,
  },
};
