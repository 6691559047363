import { Location } from "../types/locations";
import axiosHttp from "../utils/axios";
/**
 * Fetch locations
 * @param query
 * @returns
 */
export const getLocations = async (query?: string) => {
  const response = await axiosHttp.get<Location[]>(`locations`);
  return response.data;
};
