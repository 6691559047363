import { OfferPost, OfferResponse } from "../types/offers";
import { Post } from "../types/posts";
import axios from "../utils/axios";

export const getOffers = async () => {
  const response = await axios.get<OfferPost[]>(`auth/offer`);
  return response.data;
};

export const createOffer = async (postId: string, amount: number) => {
  const response = await axios.put<Post>(`auth/post/${postId}/offer/create`, { amount });
  return response.data;
};

export const cancelOffer = async (postId: string, offerId: string) => {
  const response = await axios.patch<Post>(`auth/post/${postId}/offer/${offerId}/cancel`);
  return response.data;
};
export const acceptOffer = async (postId: string, offerId: string, callback?: Function) => {
  const response = await axios.patch<OfferResponse>(`auth/post/${postId}/offer/${offerId}/accept`);
  callback && callback();
  return response.data;
};

export const declineOffer = async (postId: string, offerId: string, callback?: Function) => {
  const response = await axios.patch<Post>(`auth/post/${postId}/offer/${offerId}/decline`);
  callback && callback();
  return response.data;
};
