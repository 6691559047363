import { theme } from "../../../theme";

export const styles = {
  container: {
    marginTop: 50,
    marginBottom: 50,
    padding: theme.padding.l,
  },
  title: {
    marginBottom: 25,
    textDecoration: "underline",
  },
};
