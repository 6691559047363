import { Modal } from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";
import { useRootContext } from "../../../context/root";
import { RefetchPost } from "../../../context/types/payload";
import { ModalTypes } from "../../../types/common";
import { Post } from "../../../types/posts";
import { OfferRow } from "../../Offers/types/table-models";
import useToggleModal from "../hooks/useToggleModal";
import EditPost from "./components/EditPost";
import Offer from "./components/Offer";
import Payment from "./components/Payment";
import Transaction from "./components/Transaction";
import Location from "./components/Location";
import { styles } from "./styles";

export default function CustomModal() {
  const [model, setModel] = useState<ReactNode>(null);
  const { state } = useRootContext();
  const { toggle } = useToggleModal();

  const close = () => toggle(null);
  useEffect(() => {
    if (state.model) {
      switch (state.model?.type) {
        case ModalTypes.EDIT:
          setModel(<EditPost post={state.model.value as Post} refetchPost={state.model.callback as RefetchPost} />);
          break;
        case ModalTypes.POST:
          setModel(<Offer post={state.model.value as OfferRow} />);
          break;
        case ModalTypes.TRANSACTION:
          setModel(<Transaction post={state.model.value as Post} />);
          break;
        case ModalTypes.PAYMENT:
          setModel(<Payment post={state.model.value as Post} />);
          break;
        case ModalTypes.LOCATION:
          setModel(<Location />);
      }
    }
  }, [state.model]);

  return (
    <Modal open={(state.model?.isModalOpen as boolean) || false} onClose={close}>
      <div style={styles.modal}>{model}</div>
    </Modal>
  );
}
