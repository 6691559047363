import { CSSProperties } from "react";
import { theme } from "../../../../../theme";

export const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  } as CSSProperties,
  address: {
    width: "50%",
    margin: "auto",
    marginBottom: 10,
    padding: 7,
    backgroundColor: theme.colors.grey,
    textAlign: "center",
    boxShadow: theme.shadow.inverse,
    color: theme.colors.white,
  } as CSSProperties,
  button: {
    width: "12%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "auto",
  } as CSSProperties,
};
