import React from "react";
import { styles } from "./styles";

interface SectionHeaderProps {
  text: string;
}

function SectionHeader({ text }: SectionHeaderProps) {
  return <p style={styles.heading}>{text}</p>;
}

export default React.memo(SectionHeader);
