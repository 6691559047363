import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PaginatedPosts from "../../Posts/components/PaginatedPosts";
import { PER_PAGE } from "../../../config";
import { styles } from "./styles";
import { useRootContext } from "../../../context/root";

export default function CategoryPosts() {
  const {
    state: { location },
  } = useRootContext();
  let { id } = useParams();
  const [filter] = useState({
    page: 0,
    limit: PER_PAGE,
    category: id,
    location,
  });

  return (
    <div style={styles.container}>
      <h3 style={styles.title}>Latest posts in {id}</h3>
      <PaginatedPosts filter={filter} />
    </div>
  );
}
