import { useRoutes } from "react-router-dom";
import { useAuthToken } from "../modules/hooks/useAuthToken";
import CategoryPosts from "../modules/Categories/CategoryPosts";
import Page from "../modules/Layouts/Page";
import ProtectedRoute from "../modules/Layouts/ProtectedRoute";
import Location from "../modules/Locations/CitySelect";
import UserOffers from "../modules/Offers/UserOffers";
import Feature from "../modules/Posts/Feature";
import CreatePost from "../modules/Posts/PostCreate";
import PostView from "../modules/Posts/PostView";
import SearchPosts from "../modules/Posts/SearchPosts";
import UserPosts from "../modules/Posts/UserPosts";
import UserTransactions from "../modules/Transactions/UserTransactions";
import Login from "../modules/Users/Login";
import Register from "../modules/Users/Register";
import Settings from "../modules/Users/Settings";
import Dashboard from "../modules/Users/Dashboard";
import Root from "../modules/Layouts/Root";
import Singles from "../modules/Layouts/Singles";
import ActivateAccount from "../modules/Users/ActivateAccount";
import NotFound from "./NotFound";
import Browse from "../modules/Posts/Browse";
import ForgotPassword from "../modules/Users/ForgotPassword";
import NewPassword from "../modules/Users/NewPassword";

export const RouteView = () => {
  const { access_token } = useAuthToken();

  // Stand-alone routes
  const singles = {
    path: "",
    exact: true,
    element: <Singles />,
    children: [
      {
        path: "/location",
        exact: true,
        element: <Location />,
      },
      {
        path: "/login",
        exact: true,
        element: <Login />,
      },
      {
        path: "/register",
        exact: true,
        element: <Register />,
      },
      {
        path: "/forgot-password",
        exact: true,
        element: <ForgotPassword />,
      },
      {
        path: "/reset-password",
        exact: true,
        element: <NewPassword />,
      },
    ],
  };

  const publicRoutes = {
    path: "",
    exact: true,
    element: <Page />,
    children: [
      {
        exact: true,
        path: "/",
        element: <Feature />,
      },
      {
        exact: true,
        path: "/browse",
        element: <Browse />,
      },
      {
        exact: true,
        path: "/categories/:id",
        element: <CategoryPosts />,
      },
      {
        exact: true,
        path: "/search",
        element: <SearchPosts />,
      },
      {
        exact: true,
        path: "/post/:id",
        element: <PostView />,
      },
      {
        exact: true,
        path: "/post/:id",
        element: <PostView />,
      },
      {
        exact: true,
        path: "/activate",
        element: <ActivateAccount />,
      },
    ],
  };

  const authRoutes = {
    path: "",
    exact: true,
    element: <ProtectedRoute isAuthenticated={access_token} />,
    children: [
      {
        exact: true,
        path: "/create-post",
        element: <CreatePost />,
      },
      {
        exact: true,
        path: "/settings",
        element: <Settings />,
      },
      {
        exact: true,
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        exact: true,
        path: "/postings",
        element: <UserPosts />,
      },
      {
        exact: true,
        path: "/offers",
        element: <UserOffers />,
      },
      {
        exact: true,
        path: "/transactions",
        element: <UserTransactions />,
      },
    ],
  };

  const notFound = {
    path: "*",
    element: <NotFound />,
  };

  const common = {
    path: "",
    exact: true,
    element: <Root />,
    children: [publicRoutes, authRoutes, notFound],
  };

  const routes = useRoutes([common, singles]);
  return <>{routes}</>;
};
