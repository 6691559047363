import { theme } from "../../../../../theme";

export const styles = {
  container: {
    height: `50%`,
    margin: "10px 0px",
    width: "20%",
    maxWidth: "275px",
    padding: theme.padding.m,
    overflow: "hidden",
    backgroundColor: theme.colors.lightGrey,
    borderRadius: theme.borderRadius.s,
  },
  title: {
    padding: theme.padding.s,
    fontSize: theme.fontSize.m,
    fontWeight: 600,
    color: theme.colors.primary,
  },
  input: {
    width: "100%",
    margin: "10px 0px",
  },
  price: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  params: {
    padding: "20px 7.5px",
  },
  button: {
    width: "100%",
    alignItems: "center",
  },
  select: {
    width: "100%",
  },
};
