import { AxiosError } from "axios";
import React, { useState } from "react";
import { message } from "../../../api/users";
import { Message, MessageDocument } from "../../../types/users";

export const useUserMessage = () => {
  const [result, setResult] = useState<MessageDocument | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const doMessageUser = async (msg: Message) => {
    try {
      if (!msg.message.trim().length) return;
      if (isLoading) return;

      setIsLoading(true);
      setResult(await message(msg));
    } catch (err) {
      setError(err as AxiosError);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };
  return {
    error,
    isLoading,
    doMessageUser,
    result,
  };
};
