import { useState } from "react";
import { fetchPosts } from "../../../api/posts";
import { PER_PAGE } from "../../../config";
import { PaginationResult } from "../../../types/pagination";
import { Post, PostQueryParams } from "../../../types/posts";

export const usePosts = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [results, setResults] = useState<PaginationResult<Post>>({
    items: [] as Post[],
    total: 0,
    page: 0,
    skip: 0,
    limit: 0,
  });
  const [error, setError] = useState<Error | null>();
  const getPosts = async (params: PostQueryParams | undefined) => {
    if (!params) return;
    if (isLoading) return;
    setIsLoading(true);

    try {
      const result = await fetchPosts(params);

      setResults(result);
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    posts: results.items,
    error,
    numOfPages: Math.ceil(results.total / PER_PAGE),
    refetchPosts: getPosts,
  };
};
