import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as Sentry from "@sentry/react";
import { ENV } from "./config";

Sentry.init({
  dsn: "https://ab19ce42a1c62618aafadc0f35c1d74a@o4505921009483776.ingest.sentry.io/4505921036288000",
  integrations: [
    new Sentry.BrowserTracing({
      // URL to record
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance
  tracesSampleRate: 1.0, // Low in PROD, high in DEV
  // Session
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0, // Low in PROD, high in DEV
  environment: ENV,
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<App />);

/* Strict Mode */
/*
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
*/
