import React, { ReactNode } from "react";
import { styles } from "./styles";

interface WidgetProps {
  children: ReactNode;
  title: string;
}

function Widget({ children, title }: WidgetProps) {
  return (
    <div style={styles.container}>
      <p style={styles.header}>{title}</p>
      <div>{children}</div>
    </div>
  );
}

export default React.memo(Widget);
