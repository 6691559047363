import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Post } from "../../../../../../types/posts";
import { User } from "../../../../../../types/users";
import { styles } from "./styles";

interface PostRowProps {
  post: Post;
}

function PostRow({ post }: PostRowProps) {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      key={post._id}
      className="_container"
      style={{
        ...styles.container,
        ...(isHovered ? styles.containerHover : {}),
      }}
      onClick={() => navigate(`/post/${post._id}`)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img src={post.images[0]} style={styles.picture} alt="" />
      <div style={styles.content}>
        <p style={styles.title}>{post.title}</p>
        <div style={styles.meta}>
          <span>${post.price}</span>
          <span>{post.condition}/10</span>
        </div>
        <p style={styles.date}>
          {(post.author as User).username} - {new Date(post.createdAt).toDateString()}
        </p>
      </div>
    </div>
  );
}

export default React.memo(PostRow);
