import { ReactNode } from "react";

export type ListItem = {
  icon: ReactNode;
  value: string;
  label: string;
};

export enum ModalTypes {
  POST = "post",
  TRANSACTION = "transaction",
  PAYMENT = "payment",
  EDIT = "edit",
  LOCATION = "location",
}

export enum LocalStorageKeys {
  LOCATION = "location",
}

export enum Currency {
  USD = "USD",
  CAD = "CAD",
}
