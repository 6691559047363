import { useEffect, useState } from "react";
import { getConversionRate } from "../../../api/payments";
import { ConversionRate } from "../../../types/payment";

export const useConversionRate = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [result, setResult] = useState<ConversionRate | null>(null);

  useEffect(() => {
    (async function init() {
      doGetConversionRate();
    })();
  }, []);

  const doGetConversionRate = async () => {
    try {
      if (isLoading) return;

      setIsLoading(true);
      setResult(await getConversionRate());
    } catch (err) {
      setError(err as Error);
      console.error(err);
    }
    setIsLoading(false);
  };

  return {
    error,
    isLoading,
    btcToUsd: result?.usd,
  };
};
