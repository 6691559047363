import LoginForm from "./components/LoginForm";
import { useStyles } from "./styles";
import Logo from "../../../assets/images/logo-secondary1.png";

export default function Login() {
  const { styles, matchPhone } = useStyles();

  return (
    <div style={styles.container}>
      <div style={styles.rightContainer}>
        <LoginForm />
      </div>
      {!matchPhone && (
        <div style={styles.leftContainer}>
          <div style={styles.loginForm}>
            <img src={Logo} alt="gryp" height={125} width={200} />
          </div>
        </div>
      )}
    </div>
  );
}
