import { useEffect, useState } from "react";
import { fetchPost } from "../../../api/posts";
import { Post } from "../../../types/posts";

export const useGetPost = (id: string | undefined) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [post, setPost] = useState<Post | null>(null);
  const [error, setError] = useState<Error | null>();

  const getPost = async (id: string) => {
    try {
      setIsLoading(true);

      setPost(await fetchPost(id));
    } catch (err) {
      setError(err as Error);
      console.error(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (id) {
      (async function init() {
        await getPost(id);
      })();
    }
  }, [id]);

  return {
    isLoading,
    post,
    error,
    refetchPost: getPost,
  };
};
