import { useRootContext } from "../../../context/root";
import { ActionTypes } from "../../../context/root/actions";
import { RootState } from "../../../context/types/state";

export default function useToggleModal() {
  const { dispatch } = useRootContext();

  const toggle = (model: RootState["model"]) => {
    dispatch({
      type: ActionTypes.TOGGLE_MODAL,
      payload: {
        model,
      },
    });
  };

  return {
    toggle,
  };
}
