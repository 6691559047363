import { theme } from "../../../theme";

export const styles = {
  table: {
    width: "100%",
    boxShadow: theme.shadow.card,
  },
  head: {
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    height: 40,
  },
  icon: {
    cursor: "pointer",
    color: theme.colors.secondary,
  },
  cells: {
    padding: 15,
    fontSize: 14,
  },
};
