import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../../Common/Footer";
import Header from "../../Common/Header";
import Modal from "../../components/Modal";
import ToastMessage from "../../components/Toast";
import { useStyles } from "../styles";

export default function Root() {
  const { styles } = useStyles();
  return (
    <div style={styles.container}>
      <Header />
      <Outlet />
      <Modal />
      <ToastMessage />
      <Footer />
    </div>
  );
}
