import { Button, CircularProgress } from "@mui/material";
import React from "react";
import { useUsersContext } from "../../../../../context/users";
import { useNewAddress } from "../../../../Payments/hooks/useNewAddress";
import { styles } from "./styles";
import Block from "../Block";

export default function GenerateAddress() {
  const { state } = useUsersContext();
  const { generateAddress, isLoading, address } = useNewAddress();
  return (
    <Block title="Generate Address">
      <div style={styles.container}>
        <div style={styles.address}>{address || state.user?.wallet}</div>
        <div style={styles.button}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button variant="contained" onClick={() => generateAddress()}>
              GENERATE
            </Button>
          )}
        </div>
      </div>
    </Block>
  );
}
