import { AxiosError } from "axios";
import { useState } from "react";
import { createPayment } from "../../../api/payments";
import { useUsersContext } from "../../../context/users";
import useToggleToast from "../../components/hooks/useToggleToast";
import { ToastSeverity } from "../../components/types/toast-severity";

export const usePayment = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const { toggle } = useToggleToast();
  const {
    state: { user },
  } = useUsersContext();

  const doMakePayment = async (postId: string, callback?: Function) => {
    try {
      if (isLoading) return;
      if (!user) return;

      setIsLoading(true);
      await createPayment(postId);
      if (callback) await callback();
      toggle("Payment Successful", ToastSeverity.SUCCESS);
    } catch (err) {
      setError(err as Error);
      console.error(err);
      toggle((err as AxiosError<any>).response?.data.message, ToastSeverity.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error,
    isLoading,
    doMakePayment,
  };
};
