import { Button, Divider, MenuItem, Select, TextField } from "@mui/material";
import React, { useState } from "react";
import { useCategories } from "../../../../Categories/hooks/useCategories";
import useToggleToast from "../../../../components/hooks/useToggleToast";
import { ToastSeverity } from "../../../../components/types/toast-severity";
import { styles } from "./styles";
import { handleListenEnter } from "../../../../../utils/lib";

export default function Sidebar() {
  const { toggle } = useToggleToast();
  const { categories } = useCategories();
  const [category, setCategory] = useState<string>("#");
  const [keyword, setKeyword] = useState<string>();
  const [min, setMin] = useState<string>();
  const [max, setMax] = useState<string>();
  const urlp = new URLSearchParams();

  const onSubmit = () => {
    if (min) {
      if (!Number(min)) return toggle("Min value must be a number", ToastSeverity.ERROR);
      urlp.set("min", min);
    }
    if (max) {
      if (!Number(max)) return toggle("Max value must be a number", ToastSeverity.ERROR);
      urlp.set("max", max);
    }
    if (category && category !== "#") urlp.set("category", category);
    if (keyword) urlp.set("query", keyword);
    if (min) urlp.set("min", min);
    if (max) urlp.set("max", max);

    window.location.replace("browse?" + urlp.toString());
  };

  return (
    <div style={styles.container} onKeyDown={(e) => handleListenEnter(e, onSubmit)}>
      <div style={styles.title}>Filters</div>
      <Divider />
      <div style={styles.params}>
        <TextField style={styles.input} onChange={(e) => setKeyword(e.target.value)} id="standard-size-small" label="keyword" size="small" />
        {/** @ts-ignore */}
        <div style={styles.price}>
          <TextField style={styles.input} onChange={(e) => setMin(e.target.value)} label="min" id="standard-size-small" size="small" />
          <TextField style={styles.input} onChange={(e) => setMax(e.target.value)} label="max" id="standard-size-small" size="small" />
        </div>
        <div style={styles.input}>
          <Select size="small" label="Condition" value={category} onChange={(e) => setCategory(e.target.value)} style={styles.select}>
            <MenuItem value="#">Category</MenuItem>
            {categories.map((category) => (
              <MenuItem key={category._id} value={category.title.toLowerCase()}>
                {category.title}
              </MenuItem>
            ))}
          </Select>
        </div>
        <Button style={styles.button} variant="contained" onClick={() => onSubmit()}>
          Find
        </Button>
      </div>
    </div>
  );
}
