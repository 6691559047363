import { CheckCircleOutline } from "@mui/icons-material";
import { Button, CircularProgress, TextField } from "@mui/material";
import React, { useState } from "react";
import { useResetPassword } from "../../../hooks/useResetPassword";
import { styles } from "./styles";
import Block from "../Block";
import { validate } from "validate.js";
import { passwordConstraints } from "../../../hooks/useRegisterUser";
import useToggleToast from "../../../../components/hooks/useToggleToast";
import { JSError } from "../../../../../types/util";
import { ToastSeverity } from "../../../../components/types/toast-severity";

enum Phase {
  OLD_PASSWORD,
  NEW_PASSWORD,
}

export default function ResetPassword() {
  const [phase, setPhase] = useState<Phase>(Phase.OLD_PASSWORD);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [_newPassword, _setNewPassword] = useState("");
  const { doResetUser, isLoading, result } = useResetPassword();
  const { toggle } = useToggleToast();

  const handleNext = () => {
    if (oldPassword) {
      setPhase(Phase.NEW_PASSWORD);
    }
  };

  const handleReset = () => {
    try {
      const isValid = validate({ password: newPassword }, passwordConstraints);
      if (isValid) {
        throw new Error(isValid.password.join(" & "));
      }
      doResetUser(oldPassword, newPassword);
    } catch (err) {
      toggle((err as JSError)?.message, ToastSeverity.ERROR);
    }
  };

  return (
    <Block title="Reset Password">
      <div style={styles.container}>
        {result ? (
          <div style={styles.success}>
            <div style={styles.iconContainer}>
              <CheckCircleOutline style={styles.icon} />
            </div>
            <p style={styles.iconText}>Password Reset</p>
          </div>
        ) : (
          <div>
            {phase === Phase.OLD_PASSWORD ? (
              <div>
                <div style={styles.input}>
                  <TextField
                    fullWidth
                    label="Enter your current password"
                    size="small"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    type="password"
                  />
                </div>
                <Button style={styles.button} variant="contained" onClick={handleNext} disabled={!oldPassword}>
                  Next
                </Button>
              </div>
            ) : (
              <div>
                <div style={styles.input}>
                  <TextField
                    style={{ marginRight: 5 }}
                    label="New Password"
                    size="small"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    type="password"
                  />
                  <TextField
                    style={{ marginLeft: 5 }}
                    label="Repeat New Password"
                    size="small"
                    value={_newPassword}
                    onChange={(e) => _setNewPassword(e.target.value)}
                    type="password"
                    helperText={_newPassword && newPassword !== _newPassword ? "Passwords do not match" : ""}
                    FormHelperTextProps={{
                      style: { color: "red" },
                    }}
                  />
                </div>
                {isLoading ? (
                  <div style={styles.button}>
                    <CircularProgress />
                  </div>
                ) : (
                  <Button
                    style={styles.button}
                    variant="contained"
                    onClick={handleReset}
                    disabled={!newPassword || !_newPassword || newPassword !== _newPassword}
                  >
                    Reset
                  </Button>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </Block>
  );
}
