import React, { useState } from "react";
import validate from "validate.js";
import { register } from "../../../api/users";
import { JSError, NestErrorResponse } from "../../../types/util";
import useToggleToast from "../../components/hooks/useToggleToast";
import { ToastSeverity } from "../../components/types/toast-severity";
import { RegisterSuccess } from "../types/register";
import { UserRegister } from "../types/user";

export const passwordConstraints = {
  password: {
    length: {
      minimum: 6,
      message: "must be at least 6 characters",
    },
    format: {
      // Combined regex: checks for lowercase, uppercase, number, and symbol
      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*(),.?":{}|<>]).*$/,
      message: "must contain at least one lowercase letter, one uppercase letter, one number, and one symbol",
    },
  },
};

export const useRegisterUser = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const { toggle } = useToggleToast();

  const doRegisterUser = async (request: UserRegister, onSuccess: RegisterSuccess) => {
    try {
      if (!request.terms) throw new Error("You must accept terms to create an account");
      if (!request.email.length || !request.password.length || !request.username.length || !request.firstName.length || !request.lastName.length)
        throw new Error("Please Complete All Fields");
      const isValid = validate(request, passwordConstraints);
      if (isValid) {
        isValid.password.forEach((msg: string) => {
          throw new Error(msg);
        });
      }

      if (request.password !== request.password2) throw new Error("Password Do Not Match");
      if (isLoading) return;

      setIsLoading(true);
      await register(request);
      onSuccess();
    } catch (err) {
      setError(err as NestErrorResponse);
      console.error(err);
      toggle((err as NestErrorResponse)?.response?.data.message || (err as JSError)?.message, ToastSeverity.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error,
    isLoading,
    doRegisterUser,
  };
};
