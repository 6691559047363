import { CSSProperties } from "react";
import { theme } from "../../../theme";

export const useStyles = () => {
  return {
    styles: {
      header: {
        width: "100%",
        padding: 25,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      } as CSSProperties,
      post: {
        width: "100%",
      },
      image: {
        width: "60vw",
        height: "40vw",
        maxHeight: 400,
        maxWidth: 700,
        border: `1px solid ${theme.colors.secondary}`,
        marginRight: 15,
      },
      meta: {
        width: 700,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      } as CSSProperties,
      footer: {
        padding: 10,
      },
      description: {
        marginTop: 25,
        width: "80%",
      },
      content: {
        width: "100%",
        margin: "auto",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      } as CSSProperties,
      offer: {
        width: "30%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      } as CSSProperties,
      textbox: {
        height: 100,
        marginTop: 10,
        width: "90%",
      },
      card: {
        width: "100%",
        height: "45%",
        background: theme.colors.secondary,
      } as CSSProperties,
      field: {
        width: "100%",
        marginBottom: 10,
        background: theme.colors.white,
      },
      offerSent: {
        textAlign: "center",
        width: "100%",
        height: 150,
        padding: 20,
        color: theme.colors.green,
      } as CSSProperties,
      offerAcceptedIcon: {
        height: "100%",
        width: "100%",
      },
      offerSentIcon: {
        height: "100%",
        width: "100%",
        color: "#ffd700",
      },
      authButtons: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "left",
      } as CSSProperties,
      deleteButton: {
        marginLeft: 10,
      },
      message: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "auto",
        padding: 20,
      } as CSSProperties,
      emailIcon: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      } as CSSProperties,
      imageContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
      } as CSSProperties,
      map: {
        margin: 50,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      } as CSSProperties,
    },
  };
};
