import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import React, { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import { UserRegister } from "../../../types/user";
import { useStyles } from "../../styles";
import { theme } from "../../../../../theme";

interface CredentialsProps {
  setUser: Dispatch<SetStateAction<UserRegister>>;
  setShowCredentials: Dispatch<SetStateAction<boolean>>;
  showCredentials: boolean;
}

function Information({ setUser, setShowCredentials, showCredentials }: CredentialsProps): JSX.Element {
  const { styles } = useStyles({ showCredentials });

  return (
    <div style={styles.form}>
      <p style={styles.formTitle}>Register</p>
      <TextField
        name="firstName"
        style={{ ...styles.fields }}
        id="outlined-basic"
        label="First Name"
        variant="outlined"
        InputLabelProps={{
          style: { color: theme.colors.primary },
        }}
        onChange={(e) =>
          setUser((prev) => ({
            ...prev,
            firstName: e.target.value,
          }))
        }
      />
      <TextField
        name="lastName"
        style={{ ...styles.fields }}
        id="outlined-basic"
        label="Last Name"
        variant="outlined"
        InputLabelProps={{
          style: { color: theme.colors.primary },
        }}
        onChange={(e) =>
          setUser((prev) => ({
            ...prev,
            lastName: e.target.value,
          }))
        }
      />
      <TextField
        name="username"
        style={{ ...styles.fields, marginBottom: 4 }}
        id="outlined-basic"
        label="Username"
        variant="outlined"
        InputLabelProps={{
          style: { color: theme.colors.primary },
        }}
        onChange={(e) =>
          setUser((prev) => ({
            ...prev,
            username: e.target.value,
          }))
        }
      />
      {/** @ts-ignore */}
      <p style={styles.undertext}>Your username will be displayed to the public </p>

      <Button style={styles.nextButton} variant="contained" onClick={() => setShowCredentials(true)}>
        Next
      </Button>
      <p style={styles.footerText}>
        Already have an account?{" "}
        <Link
          style={{
            color: "white",
          }}
          to="/login"
        >
          Login
        </Link>{" "}
      </p>
    </div>
  );
}

export default React.memo(Information);
