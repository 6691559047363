import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { ListItem } from "../../../../types/common";
import { MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLoginUser } from "../../../Users/hooks/useLoginUser";
import { styles } from "../styles";
import AccountTreeIcon from "@mui/icons-material/AccountTree";

const USER_DROPDOWN: ListItem[] = [
  {
    icon: <AccountTreeIcon />,
    value: "settings",
    label: "Settings",
  },
  {
    icon: <PersonIcon />,
    value: "dashboard",
    label: "Dashboard",
  },
];

const SIGN_OUT: ListItem = {
  icon: <ExitToAppIcon />,
  value: "sign_out",
  label: "Sign Out",
};

export default function DropDownMenu() {
  const { doSignOut } = useLoginUser();
  const navigate = useNavigate();

  const signOutUser = () => {
    doSignOut();
    navigate("/browse");
  };

  return (
    <>
      {USER_DROPDOWN.map((item) => (
        <MenuItem key={item.label} onClick={() => navigate(`/${item.value}`)} value={item.value}>
          <span style={styles.dropdownIcon}>{item.icon}</span>
          {item.label}
        </MenuItem>
      ))}
      <MenuItem onClick={signOutUser} value={SIGN_OUT.value}>
        <span style={styles.dropdownIcon}>{SIGN_OUT.icon}</span>
        {SIGN_OUT.label}
      </MenuItem>
    </>
  );
}
