import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { AxiosError } from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LoginParams } from "../../../../../types/users";
import useToggleToast from "../../../../components/hooks/useToggleToast";
import { ToastSeverity } from "../../../../components/types/toast-severity";
import { useLoginUser } from "../../../hooks/useLoginUser";
import { styles } from "./styles";

export default function LoginForm() {
  const [user, setUser] = useState<LoginParams>({
    email: "",
    password: "",
  });
  const { doLoginUser, isLoading } = useLoginUser();
  const { toggle } = useToggleToast();

  const navigate = useNavigate();

  const handleLoginSuccess = () => {
    toggle("Login Successful", ToastSeverity.SUCCESS);
    navigate("/browse");
  };

  const handleLoginError = (err: AxiosError<any>) =>
    toggle((err as AxiosError<any>).response?.data.message || "Error Logging In", ToastSeverity.ERROR);

  return (
    <div style={styles.container}>
      <div style={styles.heading}>
        <p style={styles.title}>Sign in</p>
        <p style={styles.text}>You can sign in using your gryp account.</p>
      </div>
      <div style={styles.labelContainer}>Email</div>
      <TextField
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none", // Remove the border
            },
          },
        }}
        name="email"
        style={styles.fields}
        id="outlined-basic"
        variant="outlined"
        onChange={(e) =>
          setUser({
            ...user,
            email: e.target.value,
          })
        }
      />

      <div style={styles.labelContainer}>Password</div>
      <TextField
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none", // Remove the border
            },
          },
        }}
        type="password"
        name="password"
        style={{ ...styles.fields, marginBottom: 5 }}
        id="outlined-basic"
        variant="outlined"
        onChange={(e) =>
          setUser({
            ...user,
            password: e.target.value,
          })
        }
      />
      <small style={styles.forgotPassword}>
        <Link to="/forgot-password">Forgot password?</Link>
      </small>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Button style={styles.button} onClick={() => doLoginUser(user, handleLoginSuccess, handleLoginError)} variant="contained">
          Sign In
        </Button>
      )}
      <div style={styles.footer}>
        Dont have a gryp account? <Link to="/register">Create one now</Link>{" "}
      </div>
    </div>
  );
}
