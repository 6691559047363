import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import React, { Dispatch, SetStateAction } from "react";
import { theme } from "../../../../../theme";
import { UserRegister } from "../../../types/user";
import { useStyles } from "../../styles";

interface InformationProps {
  setUser: Dispatch<SetStateAction<UserRegister>>;
  setShowCredentials: Dispatch<SetStateAction<boolean>>;
  handleSubmit: () => void;
  error: Error | null;
  isLoading: boolean;
  showCredentials: boolean;
}

function Credentials({ setShowCredentials, setUser, handleSubmit, error, isLoading, showCredentials }: InformationProps): JSX.Element {
  const { styles } = useStyles({ showCredentials });
  return (
    <div style={styles.form}>
      <p style={styles.formTitle}>User Credentials</p>
      <TextField
        name="email"
        style={styles.fields}
        id="outlined-basic"
        label="Email"
        variant="outlined"
        InputLabelProps={{
          style: { color: theme.colors.primary },
        }}
        onChange={(e) =>
          setUser((prev) => ({
            ...prev,
            email: e.target.value,
          }))
        }
      />
      <TextField
        type="password"
        name="password"
        style={styles.fields}
        id="outlined-basic"
        label="Password"
        variant="outlined"
        InputLabelProps={{
          style: { color: theme.colors.primary },
        }}
        onChange={(e) =>
          setUser((prev) => ({
            ...prev,
            password: e.target.value,
          }))
        }
      />
      <TextField
        type="password"
        name="password2"
        style={styles.fields}
        id="outlined-basic"
        label="Re-enter Password"
        variant="outlined"
        InputLabelProps={{
          style: { color: theme.colors.primary },
        }}
        onChange={(e) =>
          setUser((prev) => ({
            ...prev,
            password2: e.target.value,
          }))
        }
      />

      <div
        style={{
          width: "85%",
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <input
          type="checkbox"
          id="myCheckbox"
          name="terms"
          style={{
            width: "20px",
            height: "20px",
          }}
          onChange={(e) =>
            setUser((prev) => ({
              ...prev,
              terms: !prev.terms,
            }))
          }
        />
        <label
          htmlFor="myCheckbox"
          style={{
            color: "white",
            fontSize: "1rem",
          }}
        >
          I agree to the terms and conditions
        </label>
      </div>
      <div>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <div style={{ marginTop: 16 }}>
            <Button
              style={{
                ...styles.button,
                backgroundColor: theme.colors.white,
              }}
              onClick={() => setShowCredentials(false)}
              variant="outlined"
            >
              Back
            </Button>
            <Button style={styles.button} onClick={() => handleSubmit()} variant="contained">
              Register
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default React.memo(Credentials);
