import React from "react";
import { useRootContext } from "../../../context/root";
import { ActionTypes } from "../../../context/root/actions";
import { DEFAULT_ERROR_MESSAGE } from "../../../utils/constants";
import { ToastSeverity } from "../types/toast-severity";

export default function useToggleToast() {
  const { dispatch } = useRootContext();

  const toggle = (message: string | undefined, severity: ToastSeverity) => {
    if (!message) {
      dispatch({
        type: ActionTypes.TOGGLE_TOAST,
        payload: {
          toast: {
            isToastOpen: true,
            message: DEFAULT_ERROR_MESSAGE,
            severity: ToastSeverity.INFO,
          },
        },
      });
    } else {
      dispatch({
        type: ActionTypes.TOGGLE_TOAST,
        payload: {
          toast: {
            isToastOpen: true,
            message,
            severity,
          },
        },
      });
    }
  };
  return {
    toggle,
  };
}
