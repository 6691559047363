import { useState } from "react";
import { generateNewAddress } from "../../../api/payments";

export const useNewAddress = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [address, setAddress] = useState<string>();

  const doGenerateAddress = async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);

      setAddress(await generateNewAddress());
    } catch (err) {
      setError(err as Error);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error,
    isLoading,
    address,
    generateAddress: doGenerateAddress,
  };
};
