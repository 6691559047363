type StyleModule = {
  readonly [key: string]: string;
};

export const createModuleStyleExtractor = (styles: StyleModule) => {
  return (
    key: string | (undefined | string)[],
    conditional?: Record<string, boolean>,
    additional: (string | undefined)[] = []
  ) => {
    const moduleStyles = Array.isArray(key)
      ? key.filter(Boolean).map((k) => styles[k as any])
      : [styles[key]];

    if (conditional) {
      Object.keys(conditional).forEach((style) => {
        if (conditional[style]) {
          moduleStyles.push(styles[style]);
        }
      });
    }

    return additional.filter(Boolean).concat(moduleStyles).join(" ");
  };
};
