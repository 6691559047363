import { useState } from "react";
import { getBalance } from "../../../api/payments";
import { ACCESS_TOKEN_ACCESS_KEY } from "../../../config";
import { Balance } from "../../../types/payment";

export const useBalance = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [balance, setBalance] = useState<Balance>({
    btc: 0,
    usd: 0,
  });

  const doGetBalance = async () => {
    try {
      if (!localStorage.getItem(ACCESS_TOKEN_ACCESS_KEY)) return;
      if (isLoading) return;
      setIsLoading(true);

      setBalance((await getBalance()).balance);
    } catch (err) {
      setError(err as Error);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error,
    isLoading,
    balance,
    getBalance: doGetBalance,
  };
};
