import React, { useContext, useLayoutEffect, useReducer } from "react";
import { useUser } from "../../modules/hooks/useUser";
import { UsersAction } from "../types/action";
import { UsersState } from "../types/state";
import { UsersTypes } from "./actions";
import { usersReducer } from "./reducer";
import { initialUsersState } from "./state";

interface UsersContextProps {
  state: UsersState;
  dispatch: React.Dispatch<UsersAction>;
}

interface PostsContextProviderProps {
  children: React.ReactNode;
}

export const UsersContext = React.createContext<UsersContextProps>({
  state: initialUsersState,
  dispatch: () => {},
});

export default function UsersContextProvider({ children }: PostsContextProviderProps) {
  const [state, dispatch] = useReducer(usersReducer, initialUsersState);
  const { user } = useUser();
  useLayoutEffect(() => {
    dispatch({
      type: UsersTypes.SET_USER,
      payload: {
        user,
      },
    });
  }, [user]);

  return (
    <UsersContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
}

export function useUsersContext() {
  const { dispatch, state } = useContext(UsersContext);

  return {
    dispatch,
    state,
  };
}
