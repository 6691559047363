import React, { useState } from "react";
import { Box, TextField, Button, Typography, Container, Paper, CircularProgress } from "@mui/material";
import { Lock } from "@mui/icons-material";
import { styles } from "./styles";
import { useSetNewPassword } from "../hooks/useSetNewPassword";

const NewPassword: React.FC = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { isLoading, doSetNewPasswordUser, result } = useSetNewPassword();

  // get code from url query
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");

  if (result?.success) {
    return (
      <Container maxWidth="xs">
        <Box sx={styles.box}>
          <Paper elevation={3} sx={styles.paper}>
            <Typography variant="h5" component="h1" align="center" gutterBottom sx={styles.titleTypography}>
              Password Reset
            </Typography>
            <Typography variant="body2" align="center" sx={styles.descriptionTypography}>
              Your password has been successfully reset.
            </Typography>
          </Paper>
        </Box>
      </Container>
    );
  }

  // Show error if code is missing
  if (!code) {
    return (
      <Container maxWidth="xs">
        <Box sx={styles.box}>
          <Paper elevation={3} sx={styles.paper}>
            <Typography variant="h5" component="h1" align="center" gutterBottom sx={styles.titleTypography}>
              Invalid Reset Link
            </Typography>
            <Typography variant="body2" align="center" sx={styles.descriptionTypography}>
              The password reset link appears to be invalid or expired.
            </Typography>
          </Paper>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="xs">
      <Box sx={styles.box}>
        <Paper elevation={3} sx={styles.paper}>
          <Typography variant="h5" component="h1" align="center" gutterBottom sx={{ ...styles.title, ...styles.titleTypography }}>
            Create New Password
          </Typography>
          <Typography variant="body2" align="center" sx={{ ...styles.description, ...styles.descriptionTypography }}>
            Please enter your new password below.
          </Typography>
          <div style={styles.formContainer}>
            <TextField
              fullWidth
              size="small"
              type="password"
              label="New Password"
              variant="outlined"
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                startAdornment: <Lock sx={styles.inputAdornment} />,
              }}
              sx={{
                ...styles.textField,
                ...styles.textFieldStyle,
              }}
            />
            <TextField
              fullWidth
              size="small"
              type="password"
              label="Confirm Password"
              variant="outlined"
              margin="normal"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              InputProps={{
                startAdornment: <Lock sx={styles.inputAdornment} />,
              }}
              sx={{
                ...styles.textField,
                ...styles.textFieldStyle,
              }}
            />
            {isLoading ? (
              <div style={styles.loading}>
                <CircularProgress />
              </div>
            ) : (
              <Button
                onClick={() => doSetNewPasswordUser(password, code)}
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                sx={{ ...styles.submitButton, ...styles.buttonContrastText }}
                disabled={isLoading || password !== confirmPassword || !password || !confirmPassword}
              >
                Set New Password
              </Button>
            )}
          </div>
        </Paper>
      </Box>
    </Container>
  );
};

export default NewPassword;
