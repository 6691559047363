import { Button, CircularProgress, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCreateOffer } from "../../Offers/hooks/useCreateOffer";
import { useGetPost } from "../hooks/useGetPost";
import OfferCard from "./components/OfferCard";
import { useStyles } from "./style";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { theme } from "../../../theme";
import { useDeletePost } from "../hooks/useDeletePost";
import { useCancelOffer } from "../../Offers/hooks/useCancelOffer";
import { OfferStatus } from "../../../types/offers";
import PageCard from "../../components/PageCard";
import SmallButton from "../../components/SmallButton";
import { useConversionRate } from "../../Payments/hooks/useConversionRate";
import { User } from "../../../types/users";
import { useUserMessage } from "../../Users/hooks/useUserMessage";
import EmailIcon from "@mui/icons-material/Email";
import { useUsersContext } from "../../../context/users";
import useToggleModal from "../../components/hooks/useToggleModal";
import { Currency, ModalTypes } from "../../../types/common";
import ArrowCircleRight from "@mui/icons-material/ArrowCircleRight";
import validate from "validate.js";
import { ToastSeverity } from "../../components/types/toast-severity";
import useToggleToast from "../../components/hooks/useToggleToast";
import { amountToBTC, formatCurrencyAmount } from "../../../utils/lib";

const offerValidationContraints = {
  offer: {
    numericality: {
      noStrings: true,
      greaterThan: 0,
    },
  },
};
export default function PostView() {
  let { id } = useParams();
  const {
    state: { user },
  } = useUsersContext();
  const { styles } = useStyles();

  const { post, isLoading, refetchPost } = useGetPost(id);
  const { btcToUsd } = useConversionRate();
  const { error, doCreateOffer } = useCreateOffer();
  const { doMessageUser, result } = useUserMessage();
  const { isLoading: isLoadingDeletePost, doDeletePost } = useDeletePost();
  const { error: cancelOfferError, doCancelOffer } = useCancelOffer();
  const { toggle } = useToggleModal();
  const { toggle: toggleToast } = useToggleToast();

  const navigate = useNavigate();

  const [message, setMessage] = useState<string>("Hi, when are you available?");
  const [offer, setOffer] = useState<string>("1.00");
  const [offerMade, setOfferMade] = useState<boolean>(false);
  const [imageIndex, setImageIndex] = useState<number>(0);

  useEffect(() => {
    const userOffer = post?.offers?.find(
      (o) => o.author._id === user?.sub && (o.status === OfferStatus.PENDING || o.status === OfferStatus.ACCEPTED)
    );
    if (userOffer) setOfferMade(true);
  }, [post]);

  const handleCreateOffer = async () => {
    const isValid = validate({ offer: Number(offer) }, offerValidationContraints);
    if (isValid) {
      return toggleToast(isValid.offer, ToastSeverity.ERROR);
    }

    if (post) {
      await doCreateOffer(post._id, Number(offer));
      refetchPost(post._id);
      if (!error) setOfferMade(true);
    }
  };

  const handleCancelOffer = () => {
    if (post) {
      const userOffer = post?.offers?.find((o) => o.author._id === user?.sub && o.status === OfferStatus.PENDING);
      if (userOffer) {
        if (window.confirm("Are you sure you want to cancel this offer?")) {
          doCancelOffer(post._id, userOffer._id, () => setOfferMade(false));
        }
      }
    }
  };

  const handleChangeImages = () => {
    setImageIndex((prev) => (prev + 1 > (post?.images.length || 0) - 1 ? 0 : prev + 1));
  };

  const navigateOnDelete = () => navigate("/browse");

  const isOfferAccepted = !!post?.offers?.find((o) => o.author._id === user?.sub && o.status === OfferStatus.ACCEPTED);
  const isAllowedToDelete = !post?.offers?.find((o) => o.status === OfferStatus.ACCEPTED);
  return (
    <PageCard>
      {isLoading ? (
        <CircularProgress />
      ) : (
        post &&
        post._id && (
          // Header
          <div style={styles.post}>
            <div style={styles.header}>
              <div>
                <h3>{post.title}</h3>
                <p>
                  {formatCurrencyAmount(post.price, Currency.USD)} ({amountToBTC(post.price, btcToUsd)})
                </p>
              </div>
              <div>
                <p>Posted on {post.createdAt}</p>
                <p>
                  {post.address.city}, {post.address.postalCode}
                </p>
              </div>
            </div>

            {/* // Image */}
            <div style={styles.content}>
              <div style={styles.imageContainer}>
                {post.images.length ? <img style={styles.image} src={post.images[imageIndex]} alt="/No Pics" /> : <div></div>}
                {post.images.length > 1 && (
                  <div onClick={() => handleChangeImages()}>
                    <ArrowCircleRight style={{ fontSize: 60 }} />
                  </div>
                )}
              </div>
              {/* Message & Offer side panel */}
              {!(post.author && (post.author as User).email === user?.email) ? (
                <div style={styles.offer}>
                  {result ? (
                    <div style={styles.message}>
                      <div style={styles.emailIcon}>
                        <EmailIcon style={{ color: theme.colors.green, fontSize: 40 }} />
                      </div>
                      <p>Message Sent</p>
                    </div>
                  ) : (
                    <OfferCard title="Message Author">
                      <TextField
                        style={styles.field}
                        id="message"
                        label="message"
                        multiline
                        rows={4}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                      <Button
                        variant="contained"
                        onClick={() => doMessageUser({ message, postId: post._id, title: post.title, author: (post.author as User).email })}
                      >
                        Send
                      </Button>
                    </OfferCard>
                  )}

                  {/* Create Offer & Offer Sent & Offer Accepted buttons */}
                  {offerMade ? (
                    isOfferAccepted ? (
                      <div style={styles.offerSent}>
                        <h3>Offer Accepted</h3>
                        <CheckCircleOutlineIcon style={styles.offerAcceptedIcon} />
                      </div>
                    ) : (
                      <div style={styles.offerSent}>
                        <h3 style={{ color: "#ffd700" }}>Offer Sent</h3>
                        <CheckCircleOutlineIcon style={styles.offerSentIcon} />
                        <SmallButton variant="secondary" text="Cancel Offer" onClick={() => handleCancelOffer()} />
                      </div>
                    )
                  ) : (
                    <OfferCard title="Make Offer">
                      <TextField style={styles.field} id="offer" label="Offer" value={offer} onChange={(e) => setOffer(e.target.value)} />
                      <Button onClick={handleCreateOffer} variant="contained">
                        Offer
                      </Button>
                    </OfferCard>
                  )}
                </div>
              ) : (
                <>
                  {/* Edit and Delete Buttons - User owns post */}
                  {user?.email && (
                    <div style={styles.authButtons}>
                      {isLoadingDeletePost ? (
                        <CircularProgress />
                      ) : (
                        <>
                          <SmallButton
                            variant="primary"
                            onClick={() =>
                              toggle({
                                type: ModalTypes.EDIT,
                                value: post,
                                isModalOpen: true,
                                callback: refetchPost,
                              })
                            }
                            text="Edit Post"
                          />
                          {isAllowedToDelete && (
                            <div style={styles.deleteButton}>
                              <SmallButton
                                onClick={() => {
                                  if (window.confirm("Are you sure you want to delete this post?")) {
                                    doDeletePost(post._id, navigateOnDelete);
                                  }
                                }}
                                variant="secondary"
                                text="Delete Post"
                              />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>

            {/* Footer  */}
            <div style={styles.footer}>
              <span style={styles.meta}>
                <p>
                  <b>Condition:</b> {post.condition}/10
                </p>
                <p>
                  <b>Author:</b> {(post.author as User).username}
                </p>
              </span>
              <p style={styles.description}>{post.description}</p>
            </div>

            {/* Google Maps */}
            <div style={styles.map}>
              <iframe
                id={post._id}
                width="400"
                height="300"
                loading="lazy"
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAm2SCqSMbZx2eLSiu997Oor7bo3U39Gk4&q=${post.address.city} ${post.address.state} ${post.address.country} ${post.address.postalCode}`}
              ></iframe>
            </div>
          </div>
        )
      )}
    </PageCard>
  );
}
