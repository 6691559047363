import React from "react";
import PageCard from "../../components/PageCard";
import GenerateAddress from "./components/GenerateAddress";
import ResetPassword from "./components/ResetPassword";
import WithdrawFunds from "./components/WithdrawFunds";
import { Box, Tab, Tabs } from "@mui/material";
import { styles } from "./styles";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={styles.tabPanel.wrapper}
      {...other}
    >
      {value === index && <Box sx={styles.tabPanel.content}>{children}</Box>}
    </div>
  );
}

export default function Settings() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <PageCard>
      <Box sx={styles.container}>
        <Tabs orientation="vertical" variant="scrollable" value={value} onChange={handleChange} sx={styles.tabs}>
          <Tab label="Withdraw Funds" sx={styles.tab} />
          <Tab label="Generate Address" sx={styles.tab} />
          <Tab label="Reset Password" sx={styles.tab} />
        </Tabs>

        <Box sx={styles.content}>
          <TabPanel value={value} index={0}>
            <WithdrawFunds />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <GenerateAddress />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ResetPassword />
          </TabPanel>
        </Box>
      </Box>
    </PageCard>
  );
}
