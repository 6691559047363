import axios from "axios";
import { ACCESS_TOKEN_ACCESS_KEY, API_URL } from "../config";

const axiosClient = axios.create({
  baseURL: API_URL,
  withCredentials: false,
  insecureHTTPParser: true,
});

axiosClient.interceptors.request.use((config) => {
  const token = localStorage.getItem(ACCESS_TOKEN_ACCESS_KEY);
  if (token) {
    if (config?.headers) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: "Bearer " + token,
        },
      };
    }
  }
  return config;
});

export default axiosClient;
