import * as React from "react";
import { ButtonVariants } from "../types/button-variants";
import { useStyles } from "./style";

interface SmallButtonProps {
  onClick: () => void;
  text: string;
  variant: ButtonVariants;
  icon?: React.ReactNode;
}

function SmallButton({ onClick, text, variant, icon }: SmallButtonProps) {
  const { styles } = useStyles({ variant });
  return (
    <button style={styles.button} onClick={onClick}>
      {text} {icon}
    </button>
  );
}

export default React.memo(SmallButton);
