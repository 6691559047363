import { theme } from "../../../theme";

export const styles = {
  box: {
    marginTop: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  paper: {
    padding: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  title: {
    marginBottom: 2,
  },
  description: {
    color: "text.secondary",
    marginBottom: 3,
  },
  formContainer: {
    width: "100%",
    marginTop: 1,
  },
  textField: {
    marginBottom: 2,
  },
  inputAdornment: {
    marginRight: 1,
    color: "action.active",
  },
  submitButton: {
    backgroundColor: theme.colors.secondary,
    marginTop: 2,
    marginBottom: 2,
    "&:hover": {
      backgroundColor: theme.colors.secondary,
    },
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    marginTop: 2,
    marginBottom: 2,
  },
  titleTypography: {
    color: theme.colors.primary,
  },
  descriptionTypography: {
    color: theme.colors.secondary,
  },
  textFieldStyle: {
    "& .MuiInputLabel-root": {
      color: theme.colors.primary,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: theme.colors.primary,
      },
    },
  },
  buttonContrastText: {
    color: "primary.contrastText",
  },
};
