export function isValidBitcoinAddress(address: string): boolean {
  // Legacy address format (starts with 1 for mainnet, m or n for testnet)
  const legacyFormat = /^[1mn][a-km-zA-HJ-NP-Z1-9]{25,34}$/;

  // Segwit address format (starts with 3 for mainnet, 2 for testnet)
  const segwitFormat = /^[23][a-km-zA-HJ-NP-Z1-9]{25,34}$/;

  // Native Segwit bech32 format (starts with bc1 for mainnet, tb1 for testnet)
  const bech32Format = /^(bc1|tb1)[a-zA-HJ-NP-Z0-9]{25,87}$/;

  return legacyFormat.test(address) || segwitFormat.test(address) || bech32Format.test(address);
}
