import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { styles } from "./style";
import { theme } from "../../../../theme";

interface OfferCardProps {
  title: string;
  children: any;
}

function OfferCard({ title, children }: OfferCardProps) {
  return (
    <Card style={styles.card}>
      <CardHeader style={styles.header} title={title} titleTypographyProps={{ fontSize: 14, color: theme.colors.white }} />
      <CardContent>{children}</CardContent>
    </Card>
  );
}

export default React.memo(OfferCard);
