import { CSSProperties } from "react";
import { theme } from "../../../../../../theme";

export const styles: { [key: string]: CSSProperties } = {
  card: {
    marginBottom: 40,
    textDecoration: "none",
    maxWidth: 250,
  },
  image: {
    maxWidth: 250,
    minHeight: 125,
    width: "100%",
    height: "10vw",
  },
  avatar: {
    backgroundColor: theme.colors.primary,
  },
  cardInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  } as CSSProperties,
  cardTitle: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
};
