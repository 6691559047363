import React from "react";
import { Link } from "react-router-dom";
import { useCategories } from "../hooks/useCategories";
import { styles } from "./style";

export default function CategoryBar() {
  const { isLoading, error, categories } = useCategories();
  return (
    <div style={styles.container}>
      <ul style={styles.list}>
        {!isLoading &&
          !error &&
          categories.map((category) => (
            <Link key={category._id} to={`/browse?category=${category.title.toLowerCase()}`} style={styles.link}>
              <li id={category._id} style={styles.list}>
                {category.title}
              </li>
            </Link>
          ))}
      </ul>
    </div>
  );
}
