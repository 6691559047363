import { Post } from "./posts";

export enum PaymentStatus {
  ESCROW = "ESCROW",
  PAID = "PAID",
  REFUNDED = "REFUNDED",
}

export type Payment = {
  payment_address: string;
  offer: string;
  amount: number;
  price: number;
  currency: string;
  _id: string;
  createdAt: string;
  updatedAt: string;
  status: PaymentStatus;
};

export type Balance = {
  btc: number;
  usd: number;
};

export type ConversionRate = {
  usd: number;
};

export type PaymentPost = Post & Payment;
