import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { usePostsContext } from "../../../../../context/posts";
import { ModalTypes } from "../../../../../types/common";
import { OfferStatus } from "../../../../../types/offers";
import useToggleModal from "../../../../components/hooks/useToggleModal";
import SmallButton from "../../../../components/SmallButton";
import { useConversionRate } from "../../../../Payments/hooks/useConversionRate";
import { styles } from "./styles";
import { Post } from "../../../../../types/posts";
import { TransactionStatus } from "../../../../../types/transactions";
import { User } from "../../../../../types/users";
import { useUsersContext } from "../../../../../context/users";
import { PaymentStatus } from "../../../../../types/payment";

export default function AwaitingPayment() {
  const { state, refetchOffersSent } = usePostsContext();
  const { btcToUsd } = useConversionRate();
  const { toggle } = useToggleModal();
  const {
    state: { user },
  } = useUsersContext();

  useEffect(() => {
    refetchOffersSent();
  }, []);

  const pendingPayments: Post[] = useMemo(() => {
    return state.offersSent.filter((post) => {
      const paymentAlreadyMade = post.payments.find((payment) => payment.status === PaymentStatus.PAID || payment.status === PaymentStatus.ESCROW);
      const acceptedOffer = post.offers.find((offer) => offer.status === OfferStatus.ACCEPTED && offer.author._id === user?.sub);
      return !paymentAlreadyMade && acceptedOffer;
    });
  }, [state.offersSent, user]);

  return (
    <div>
      {pendingPayments.length === 0 ? (
        <p style={styles.text}>No posts awaiting payments... </p>
      ) : (
        <ul style={styles.list}>
          {pendingPayments.length > 0 &&
            pendingPayments.map((post) => (
              <li key={post._id} style={styles.listItem}>
                <Link to={`/${post._id}`}>
                  <p>{post.title}</p>
                </Link>
                <span style={styles.price}>
                  ${post.offers.find((offer) => offer.status === OfferStatus.ACCEPTED)?.amount || "N/A"} (
                  {btcToUsd && Number(post.offers.find((offer) => offer?.status === OfferStatus.ACCEPTED)?.amount) / btcToUsd} BTC)
                </span>
                <SmallButton
                  variant="secondary"
                  text="Pay Now"
                  onClick={() => toggle({ type: ModalTypes.PAYMENT, value: post, isModalOpen: true })}
                />
              </li>
            ))}
        </ul>
      )}
    </div>
  );
}
