import React, { ReactNode } from "react";
import { useStyles } from "./styles";
import cssStyles from "./styles.module.scss";

interface PageCardProps {
  children: ReactNode;
}

function PageCard({ children }: PageCardProps) {
  const { styles } = useStyles();
  return (
    <div className={cssStyles._container} style={styles.container}>
      {children}
    </div>
  );
}

export default React.memo(PageCard);
