import { CircularProgress, FormControl, InputLabel, MenuItem, Select, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { OfferStatus } from "../../../types/offers";
import { capitalizeFirstLetter } from "../../../utils/lib";
import { OfferRow, TransactionRow } from "../../Offers/types/table-models";
import PageCard from "../PageCard";
import SectionHeader from "../SectionHeader";
import Table from "../Table";
import { TableColumn } from "../types/table";
import { styles } from "./styles";

type TableOptions = OfferRow | TransactionRow;

interface TabTableProps<T> {
  header: string;
  tabLabels: string[];
  isLoading: boolean;
  statusEntries: string[];
  columns: TableColumn<T>[];
  tabOneEntries: TableOptions[];
  tabTwoEntries: TableOptions[];
}

export default function TabTable({
  header,
  tabLabels,
  isLoading,
  columns,
  tabOneEntries,
  tabTwoEntries,
  statusEntries,
}: TabTableProps<TableOptions>) {
  const [tab, setTab] = useState<number>(0);
  const [status, setStatus] = useState<string>(statusEntries[0]);
  const [posts, setPosts] = useState<TableOptions[]>([]);

  useEffect(() => {
    let filteredOffers: TableOptions[] = [];
    const tabMapping: Record<number, TableOptions[]> = {
      0: tabOneEntries,
      1: tabTwoEntries,
    };

    filteredOffers = tabMapping[tab].filter((post) => post.status === status);

    setPosts(filteredOffers);
  }, [status, tab, tabOneEntries, tabTwoEntries]);

  return (
    <PageCard>
      <div style={styles.container}>
        <SectionHeader text={header} />
        <div style={styles.section}>
          <div style={styles.tabs}>
            {tab !== undefined && setTab && (
              <Tabs value={tab} onChange={(e, val) => setTab(val)}>
                {tabLabels.map((label) => (
                  <Tab key={label} label={label} />
                ))}
              </Tabs>
            )}
          </div>
          <div style={styles.selectContainer}>
            <FormControl variant="standard" style={styles.select}>
              <InputLabel>Status</InputLabel>
              <Select value={status} onChange={(e) => setStatus(e.target.value as OfferStatus)}>
                {statusEntries.map((s: string) => (
                  <MenuItem key={s} value={s}>
                    {capitalizeFirstLetter(s)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={styles.table}>
            {isLoading ? (
              <div style={styles.loadingIcon}>
                <CircularProgress />
              </div>
            ) : (
              <Table columns={columns} rows={posts} />
            )}
          </div>
        </div>
      </div>
    </PageCard>
  );
}
