import { LocalStorageKeys } from "../../types/common";
import { LocalStorage } from "../../utils/local-storage";
import { RootState } from "../types/state";

export const initialRootState: RootState = {
  isSidesheetOpen: false,
  model: null,
  toast: null,
  location: LocalStorage.get(LocalStorageKeys.LOCATION),
};
