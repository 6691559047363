import React, { useEffect, useMemo } from "react";
import { mapPostToTableRow } from "../../../utils/lib";
import { OfferRow } from "../types/table-models";
import { ModalTypes } from "../../../types/common";
import { Link } from "react-router-dom";
import { TableColumn } from "../../components/types/table";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import TabTable from "../../components/TabTable";
import { OfferStatus } from "../../../types/offers";
import { usePostsContext } from "../../../context/posts";
import useToggleModal from "../../components/hooks/useToggleModal";
import { useUsersContext } from "../../../context/users";

const TAB_LABELS = ["Received", "Sent"];
const NUM_RECENT_OFFERS = 100;

export default function UserOffers() {
  const {
    state: { user },
  } = useUsersContext();
  const { state, refetchOffersSent, refetchPosts } = usePostsContext();
  const { toggle } = useToggleModal();

  useEffect(() => {
    if (user) {
      refetchOffersSent();

      refetchPosts({
        user: user?.sub,
        limit: NUM_RECENT_OFFERS,
      });
    }
  }, [user]);

  const filteredSentOffers = state.offersSent.map((post) => {
    post.offers = post.offers.filter((offer) => offer.author._id === user?.sub);
    return post;
  });
  // Offers user has sent
  const mappedSentOffers = useMemo(() => mapPostToTableRow(filteredSentOffers), [state.offersSent]);
  // Offers user has received
  const mappedReceivedOffers = useMemo(() => mapPostToTableRow(state.posts), [state.posts]);

  const TABLE_HEADERS: TableColumn<OfferRow>[] = [
    {
      label: "Post",
      render: ({ _id, title }) => <Link to={`/post/${_id}`}>{title}</Link>,
    },
    {
      label: "Listed Price",
      render: ({ price }) => <>${price.toFixed(2)}</>,
    },
    {
      label: "Listed On",
      render: ({ createdAt }) => <>{new Date(createdAt).toDateString()}</>,
    },
    { label: "Amount", render: ({ offer }) => <>${offer.amount}</> },
    {
      label: "Offered",
      render: ({ offer }) => <>${offer.amount}</>,
    },
    { label: "Status", render: ({ offer }) => <>{offer.status}</> },
    {
      label: "Author",
      render: ({}) => <> </>,
    },
    {
      label: "",
      render: (offer) => (
        <AddCircleOutlineOutlined style={{ cursor: "pointer" }} onClick={() => toggle({ type: ModalTypes.POST, value: offer, isModalOpen: true })} />
      ),
    },
  ];

  return (
    <TabTable
      header="My Offers"
      tabLabels={TAB_LABELS}
      isLoading={false}
      // @ts-ignore next-line
      columns={TABLE_HEADERS}
      statusEntries={Object.keys(OfferStatus)}
      tabOneEntries={mappedReceivedOffers}
      tabTwoEntries={mappedSentOffers}
    />
  );
}
