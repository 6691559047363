import { AxiosError } from "axios";
import { useState } from "react";
import { editPost } from "../../../api/posts";
import { useUsersContext } from "../../../context/users";
import { Post } from "../../../types/posts";
import useToggleToast from "../../components/hooks/useToggleToast";
import { ToastSeverity } from "../../components/types/toast-severity";

export const useEditPost = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const { toggle } = useToggleToast();
  const {
    state: { user },
  } = useUsersContext();

  const doEditPost = async (post: Post, callback?: Function) => {
    try {
      if (isLoading) return;
      if (!user) return;

      setIsLoading(true);
      await editPost(post);
      callback && callback();
      toggle("Post Edited", ToastSeverity.SUCCESS);
    } catch (err) {
      setError(err as Error);
      console.error(err);
      toggle((err as AxiosError<any>).response?.data.message, ToastSeverity.ERROR);
    } finally {
      setIsLoading(false);
    }
  };
  return {
    error,
    isLoading,
    doEditPost,
  };
};
