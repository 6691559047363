import React from "react";
import { OfferRow, TransactionRow } from "../../Offers/types/table-models";
import { styles } from "./styles";
import { TableColumn } from "../types/table";

type TableOptions = OfferRow | TransactionRow;
interface TableProps<T> {
  columns: TableColumn<T>[];
  rows: TableOptions[];
}

export default function Table({ columns, rows }: TableProps<TableOptions>) {
  return (
    <table style={styles.table}>
      <thead>
        <tr style={styles.head}>
          {columns.map((header) => (
            <th key={header.label}>{header.label}</th>
          ))}
          <th></th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row, i) => (
          <tr key={row._id}>
            {columns.map((col, i) => (
              <td key={i} style={styles.cells}>
                {col.render(row)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
