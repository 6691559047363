import React from "react";
import { useUsersContext } from "../../../../../context/users";
import { styles } from "./styles";
import useToggleToast from "../../../../components/hooks/useToggleToast";
import { ToastSeverity } from "../../../../components/types/toast-severity";

export default function CryptoAddress() {
  const {
    state: { user },
  } = useUsersContext();
  const { toggle } = useToggleToast();

  const handleCopy = () => {
    if (user?.wallet) {
      navigator.clipboard
        .writeText(user.wallet)
        .then(() => toggle("Address copied", ToastSeverity.SUCCESS))
        .catch(() => toggle("Failed to copy address", ToastSeverity.ERROR));
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.address}>{user?.wallet}</div>
      <button style={styles.copyButton} onClick={handleCopy}>
        Copy
      </button>
    </div>
  );
}
