import { theme } from "../../../../theme";

export const styles = {
  card: {
    margin: "auto",
    minWidth: "100%",
  },
  header: {
    background: theme.colors.primary,
    maxHeight: 20,
  },
};
