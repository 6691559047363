import { UsersAction } from "../types/action";
import { UsersState } from "../types/state";
import { SetUser, UsersTypes } from "./actions";

export function usersReducer(state: UsersState, action: UsersAction): UsersState {
  switch (action.type) {
    case UsersTypes.SET_USER:
      return {
        ...state,
        user: (action.payload as SetUser).user,
      };
    default:
      return {
        ...state,
      };
  }
}
