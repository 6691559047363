import { useState } from "react";
import { getOffers } from "../../../api/offers";
import { useUsersContext } from "../../../context/users";
import { OfferPost } from "../../../types/offers";

/**
 * Gets offers the user has sent
 */
export const useGetUserOffer = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [posts, setPosts] = useState<OfferPost[]>([]);
  const [error, setError] = useState<Error | null>();
  const { state } = useUsersContext();

  const doGetOffers = async () => {
    try {
      if (!state.user) return;
      if (isLoading) return;
      setIsLoading(true);

      setPosts(await getOffers());
    } catch (error) {
      setError(error as Error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    posts,
    error,
    refetchOffersSent: doGetOffers,
  };
};
