import { CSSProperties } from "react";
import { theme } from "../../../../../theme";

export const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: 7,
    backgroundColor: theme.colors.white,
    boxShadow: theme.shadow.inverse,
  } as CSSProperties,
  address: {
    flex: 1,
    color: theme.colors.secondary,
    textAlign: "center",
  } as CSSProperties,
  copyButton: {
    cursor: "pointer",
    padding: "4px 8px",
    border: "none",
    borderRadius: "4px",
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    fontSize: "14px",
  } as CSSProperties,
};
