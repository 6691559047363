import { CSSProperties } from "react";
import { theme } from "../../../theme";

export const styles: { [key: string]: CSSProperties | { [key: string]: CSSProperties } } = {
  container: {
    padding: 25,
    overflow: "auto",
  },
  list: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    color: theme.colors.secondary,
  },
  link: {
    textDecoration: "none",
    hover: {
      textDecoration: "underline",
    },
  },
};
