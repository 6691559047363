/**
 * Gets all Users transactions -> in instances where user is a buyer OR a seller
 */

import { useState } from "react";
import { getTransactions } from "../../../api/transactions";
import { useUsersContext } from "../../../context/users";
import { Post } from "../../../types/posts";

export const useTransactions = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<Post[]>([]);
  const [error, setError] = useState<Error | null>();
  const {
    state: { user },
  } = useUsersContext();

  const doGetTransactions = async () => {
    try {
      if (isLoading) return;
      if (!user) return;

      setIsLoading(true);

      setTransactions(await getTransactions());
    } catch (error) {
      setError(error as Error);
    }
    setIsLoading(false);
  };

  return {
    isLoading,
    transactions,
    error,
    refetchTransactions: doGetTransactions,
  };
};
