import { CSSProperties } from "react";
import { theme } from "../../../../../theme";

export const styles: { [key: string]: CSSProperties } = {
  title: {
    color: theme.colors.primary,
    textAlign: "center",
  },
  list: {
    listStyleType: "none",
    padding: "0",
    textAlign: "center",
  },
  listItem: {
    cursor: "pointer",
    margin: "10px 0",
    padding: 6,
    width: "100%",
    boxSizing: "border-box",
    transition: "background-color 0.3s ease",
    color: theme.colors.secondary,
    border: `1px solid ${theme.colors.primary}`,
  },
  button: {
    padding: "10px 20px",
    cursor: "pointer",
    width: "100%",
    boxSizing: "border-box",
  },
  primary: {
    backgroundColor: "#007bff",
    color: "#fff",
  },
  secondary: {
    backgroundColor: "#6c757d",
    color: "#fff",
  },
  listItemHover: {
    backgroundColor: "#f1f1f1",
  },
};
