import React, { useState } from "react";
import { Box, TextField, Button, Typography, Container, Paper, CircularProgress } from "@mui/material";
import { Email } from "@mui/icons-material";
import { useForgotPassword } from "../hooks/useForgotPassword";
import { styles } from "./styles";

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const { doForgotPassword, isLoading, result } = useForgotPassword();

  if (result) {
    return (
      <Container maxWidth="xs">
        <Box sx={styles.box}>
          <Paper elevation={3} sx={styles.paper}>
            <Typography variant="h5" component="h1" align="center" gutterBottom sx={styles.titleTypography}>
              Email Sent
            </Typography>
            <Typography variant="body2" align="center" sx={styles.descriptionTypography}>
              An email has been sent with instructions on how to reset your password.
            </Typography>
          </Paper>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="xs">
      <Box sx={styles.box}>
        <Paper elevation={3} sx={styles.paper}>
          <Typography variant="h5" component="h1" align="center" gutterBottom sx={{ ...styles.title, ...styles.titleTypography }}>
            Forgot Password
          </Typography>
          <Typography variant="body2" align="center" sx={{ ...styles.description, ...styles.descriptionTypography }}>
            Enter your email address and we'll send you a link to reset your password.
          </Typography>
          <div style={styles.formContainer}>
            <TextField
              fullWidth
              size="small"
              label="Email"
              variant="outlined"
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                startAdornment: <Email sx={styles.inputAdornment} />,
              }}
              sx={{ ...styles.textField, ...styles.textFieldStyle }}
            />
            {isLoading ? (
              <div style={styles.loading}>
                <CircularProgress />
              </div>
            ) : (
              <Button
                onClick={() => doForgotPassword(email)}
                type="submit"
                fullWidth
                variant="contained"
                size="medium"
                sx={styles.submitButton}
                disabled={isLoading}
              >
                Reset Password
              </Button>
            )}
          </div>
        </Paper>
      </Box>
    </Container>
  );
};

export default ForgotPassword;
