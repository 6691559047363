import { PER_PAGE } from "../config";
import { Offer } from "./offers";
import { Payment } from "./payment";
import { Transaction } from "./transactions";
import { User } from "./users";

export type CreatePostAddress = {
  street: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
};

export type Address = CreatePostAddress & {
  _id: string;
};

export type CreatePost = {
  title: string;
  description: string;
  images: Array<File> | null;
  price: number;
  condition: number;
  address: CreatePostAddress;
  category: string[];
};

export enum PostStatus {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
  PENDING = "PENDING",
  SOLD = "SOLD",
}

export type Post = {
  _id: string;
  title: string;
  description: string;
  author: User | string;
  condition: number;
  createdAt: string;
  updatedAt: string;
  status: PostStatus;
  price: number;
  images: string[];
  address: Address;
  category: string[];
  offers: Offer[];
  payments: Payment[];
  transactions: Transaction[];
};

export class PostQueryParams {
  query?: string;
  user?: string;
  page?: number = 0;
  limit?: number = PER_PAGE;
  status?: PostStatus = PostStatus.OPEN;
  category?: string;
  location?: string | null;
  min?: string;
  max?: string;
}
