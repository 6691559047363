import { CSSProperties } from "react";
import { theme } from "../../../../../theme";

export const styles: { [key: string]: CSSProperties } = {
  container: {
    width: "100%",
    margin: "auto",
  } as CSSProperties,
  price: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 7,
    borderRadius: theme.borderRadius.l,
    backgroundColor: theme.colors.green,
    margin: 10,
  },
  meta: {
    fontWeight: "bold",
    marginRight: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  button: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 20,
  } as CSSProperties,
  fee: {
    color: theme.colors.red,
    fontWeight: 700,
    fontSize: 20,
  },
  cost: {
    fontSize: 20,
  },
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
};
