import React, { useEffect, useState } from "react";
import { usePostsContext } from "../../../../../context/posts";
import { ModalTypes } from "../../../../../types/common";
import { TransactionStatus } from "../../../../../types/transactions";
import useToggleModal from "../../../../components/hooks/useToggleModal";
import { styles } from "./styles";
import { Post } from "../../../../../types/posts";

export default function PendingTransactions() {
  const [pendingTransactions, setPendingTransactions] = useState<Post[]>([]);
  const { state, refetchUserTransactions } = usePostsContext();
  const { toggle } = useToggleModal();

  useEffect(() => {
    refetchUserTransactions();
  }, []);

  useEffect(() => {
    if (state.transactions) {
      const pendingTransactionPosts = state.transactions.filter((post) =>
        post.transactions.find((transaction) => transaction.status === TransactionStatus.PENDING)
      );
      setPendingTransactions(pendingTransactionPosts);
    }
  }, [state.transactions]);

  return (
    <div>
      {pendingTransactions.length > 0 ? (
        <ul>
          {pendingTransactions.map((transaction) => (
            <li
              key={transaction._id}
              style={{
                ...styles.listItem,
              }}
            >
              <div onClick={() => toggle({ type: ModalTypes.TRANSACTION, value: transaction, isModalOpen: true })}>
                <p>{transaction.title}</p>
              </div>
              <span style={styles.price}>${transaction.transactions.find((t) => t.status === TransactionStatus.PENDING)?.amount}</span>
            </li>
          ))}
        </ul>
      ) : (
        <p style={styles.text}>No pending transactions... </p>
      )}
    </div>
  );
}
