import Card from "./components/PostCard";
import React, { useEffect, useState } from "react";
import { styles } from "./style";
import { CircularProgress } from "@mui/material";
import { PostQueryParams } from "../../../../types/posts";
import { usePosts } from "../../hooks/usePosts";

interface PaginatedPostsProps {
  filter?: PostQueryParams;
}

export default function PaginatedPosts({ filter }: PaginatedPostsProps) {
  const [_filter, setFilter] = useState<PostQueryParams | undefined>(filter);

  useEffect(() => {
    setFilter((prev) => ({ ...prev, page: 0 }));
  }, []);

  const { posts, isLoading, refetchPosts } = usePosts();

  useEffect(() => {
    refetchPosts(_filter);
  }, [_filter]);

  return (
    <div>
      {isLoading ? (
        <div style={styles.loading}>
          <CircularProgress />
        </div>
      ) : (
        <div style={styles.posts}>
          {posts.map((post) => (
            <Card key={post._id} post={post} />
          ))}
        </div>
      )}
    </div>
  );
}
