import { CircularProgress } from "@mui/material";
import React from "react";
import { useActivateAccount } from "../../hooks/useActivateAccount";

type ActivateProps = {
  activationCode: string | null;
};

export default function Activate({ activationCode }: ActivateProps) {
  const { isSuccess, isLoading, error } = useActivateAccount(activationCode);

  if (!isSuccess || error) {
    return <p>Account not found.</p>;
  }

  return <div>{isLoading ? <CircularProgress /> : <p>Account Activated!</p>}</div>;
}
