import React from "react";
import { Button, CircularProgress } from "@mui/material";
import { usePostsContext } from "../../../../../context/posts";
import { TransactionStatus } from "../../../../../types/transactions";
import { useCancelTransaction } from "../../../../Transactions/hooks/useCancelTransaction";
import { useCompleteTransaction } from "../../../../Transactions/hooks/useCompleteTransaction";
import useToggleModal from "../../../hooks/useToggleModal";
import Header from "../Header";
import { styles } from "./styles";
import { useUsersContext } from "../../../../../context/users";
import { Post } from "../../../../../types/posts";
import { User } from "../../../../../types/users";

interface TransactionProps {
  post: Post;
}

export default function Transaction({ post }: TransactionProps) {
  const { doCompleteTransaction, isLoading: isLoadingComplete } = useCompleteTransaction();
  const { doCancelTransaction, isLoading: isLoadingCancel } = useCancelTransaction();
  const { refetchUserTransactions } = usePostsContext();
  const { toggle } = useToggleModal();
  const { state } = useUsersContext();

  const closeModal = async () => {
    await refetchUserTransactions();
    toggle(null);
  };
  const isSeller = state.user?.sub === post.author;

  const transaction = post.transactions.find(
    (t) => t.status === TransactionStatus.PENDING && ((t.seller as User)._id === state.user?.sub || (t.buyer as User)._id === state.user?.sub)
  );

  return (
    <>
      {transaction && (
        <div>
          <Header post={post} model="Transaction" status={transaction.status} />
          <div style={styles.status}>
            <div>
              <b>Transaction Status: </b>
              {transaction.status}
            </div>
          </div>
          <h3 style={{ ...styles.amount, ...styles.price }}>
            <span style={styles.meta}>Offer:</span> ${transaction.amount}
          </h3>
          {transaction.status === TransactionStatus.PENDING && (
            <div style={styles.button}>
              {isLoadingCancel || isLoadingComplete ? (
                <CircularProgress />
              ) : (
                <div>
                  {isSeller ? (
                    <Button onClick={() => doCancelTransaction(post._id, transaction._id, closeModal)} variant="contained">
                      Cancel Transaction
                    </Button>
                  ) : (
                    <Button onClick={() => doCompleteTransaction(post._id, transaction._id, closeModal)} variant="contained">
                      Handshake
                    </Button>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}
