import { Balance, PaymentPost } from "../types/payment";
import axios from "../utils/axios";

export const getBalance = async () => {
  const response = await axios.get<{ balance: Balance }>(`auth/payment`);
  return response.data;
};

export const getConversionRate = async () => {
  const response = await axios.get<{ usd: number }>(`auth/payment/rate`);
  return response.data;
};

export const getCryptoAddress = async () => {
  const response = await axios.get<{ address: string }>(`auth/payment/address`);
  return response.data;
};

export const createPayment = async (postId: string) => {
  const response = await axios.post<PaymentPost>(`auth/post/${postId}/payment`);
  return response.data;
};

export const generateNewAddress = async () => {
  const response = await axios.post<string>(`auth/user/address`);
  return response.data;
};

export const withdrawFunds = async (address: string) => {
  const response = await axios.post<void>(`auth/withdraw/${address}/payment`);
  return response.data;
};
