import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PER_PAGE } from "../../../config";
import { PostStatus } from "../../../types/posts";
import PostsList from "../components/PostsList";
import { usePosts } from "../hooks/usePosts";
import { styles } from "./styles";

export default function SearchPosts() {
  const search = useLocation().search;
  const searchTerm = new URLSearchParams(search).get("q");

  const [page, setPage] = useState(0);
  const { posts, isLoading, refetchPosts, numOfPages } = usePosts();

  useEffect(() => {
    refetchPosts({
      query: searchTerm || "",
      page: 0,
      limit: PER_PAGE,
      status: PostStatus.OPEN,
    });
  }, [searchTerm]);

  return (
    <div style={styles.container}>
      <h3 style={styles.title}>Latest posts like '{searchTerm}'</h3>
      <PostsList posts={posts} isLoading={isLoading} />
      <Pagination count={numOfPages} page={page + 1} onChange={(e, p) => setPage(p - 1)} />
    </div>
  );
}
