import { CSSProperties } from "react";
import { theme } from "../../../../../theme";

export const styles = {
  list: {
    listStyle: "none",
  },
  listItem: {
    padding: 15,
    borderRadius: theme.borderRadius.l,
    color: theme.colors.white,
    margin: "7px 0 7px 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textDecoration: "none",
    backgroundColor: theme.colors.primary,
  } as CSSProperties,
  price: {
    fontWeight: "bold",
  },
  link: {
    textDecoration: "none",
    color: theme.colors.white,
  },
  text: {
    color: theme.colors.white,
  },
};
