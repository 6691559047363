import { CSSProperties } from "react";
import { theme } from "../../../../../theme";

export const styles = {
  container: {},
  row: {
    width: "100%",
    display: "flex",
    flexDiection: "row",
    justifyContent: "space-between",
  } as CSSProperties,
  meta: {
    marginRight: 10,
  },
  price: {
    padding: 7,
    borderRadius: theme.borderRadius.l,
    backgroundColor: theme.colors.green,
  },
  offer: {
    margin: "auto",
    marginTop: 35,
    marginBottom: 45,
    textAlign: "center",
  } as CSSProperties,
  buttons: {
    width: "100%",
    display: "flex",
    flexDiection: "row",
    justifyContent: "center",
  } as CSSProperties,
  decline: {
    color: theme.colors.red,
    border: `1px solid ${theme.colors.red}`,
    marginLeft: 10,
  },
};
