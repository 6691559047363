import React, { ReactNode } from "react";
import { styles } from "./styles";

interface BlockProps {
  title: string;
  children: ReactNode;
}

function Block({ title, children }: BlockProps) {
  return (
    <div style={styles.container}>
      <p style={styles.header}>{title}</p>
      <div>{children}</div>
    </div>
  );
}

export default React.memo(Block);
