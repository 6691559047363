import React, { useContext, useLayoutEffect, useReducer } from "react";
import { useGetUserOffer } from "../../modules/Offers/hooks/useGetUserOffer";
import { usePosts } from "../../modules/Posts/hooks/usePosts";
import { useTransactions } from "../../modules/Transactions/hooks/useTransactions";
import { PostQueryParams } from "../../types/posts";
import { PostsAction } from "../types/action";
import { PostsState } from "../types/state";
import { PostsTypes } from "./actions";
import { postsReducer } from "./reducer";
import { initialPostsState } from "./state";

interface PostsContextProps {
  state: PostsState;
  dispatch: React.Dispatch<PostsAction>;
  refetchPosts: (filter: PostQueryParams) => void;
  refetchOffersSent: () => void;
  refetchUserTransactions: () => void;
  isLoading: boolean;
  numOfPages: number;
}

interface PostsContextProviderProps {
  children: React.ReactNode;
}

export const PostsContext = React.createContext<PostsContextProps>({
  state: initialPostsState,
  dispatch: () => {},
  refetchPosts: () => {},
  refetchOffersSent: () => {},
  refetchUserTransactions: () => {},
  isLoading: false,
  numOfPages: 1,
});

export default function PostsContextProvider({ children }: PostsContextProviderProps) {
  const [state, dispatch] = useReducer(postsReducer, initialPostsState);
  const { transactions, refetchTransactions, isLoading: transactionsLoading } = useTransactions();
  const { posts, refetchPosts, isLoading, numOfPages } = usePosts();
  const { posts: offersSent, refetchOffersSent, isLoading: offerLoading } = useGetUserOffer();

  useLayoutEffect(() => {
    dispatch({
      type: PostsTypes.SET_USER_POSTS,
      payload: {
        posts: posts,
      },
    });
  }, [posts]);

  useLayoutEffect(() => {
    dispatch({
      type: PostsTypes.SET_USER_OFFERS_MADE,
      payload: {
        offersSent,
      },
    });
  }, [offersSent]);

  useLayoutEffect(() => {
    dispatch({
      type: PostsTypes.SET_USER_TRANSCATION,
      payload: {
        transactions,
      },
    });
  }, [transactions]);

  return (
    <PostsContext.Provider
      value={{
        state,
        dispatch,
        refetchPosts,
        refetchOffersSent,
        refetchUserTransactions: refetchTransactions,
        isLoading: isLoading || offerLoading || transactionsLoading,
        numOfPages,
      }}
    >
      {children}
    </PostsContext.Provider>
  );
}

export function usePostsContext() {
  const { dispatch, state, refetchPosts, refetchOffersSent, refetchUserTransactions, isLoading, numOfPages } = useContext(PostsContext);

  return {
    dispatch,
    state,
    isLoading,
    numOfPages,
    refetchPosts,
    refetchOffersSent,
    refetchUserTransactions,
  };
}
