import React from "react";
import { useLocation } from "react-router-dom";
import PageCard from "../../components/PageCard";
import Activate from "./components/Activate";

export default function ActivateAccount() {
  const search = useLocation().search;
  const searchTerm = new URLSearchParams(search).get("code");

  return (
    <PageCard>
      <Activate activationCode={searchTerm} />
    </PageCard>
  );
}
