import { CSSProperties } from "react";
import { theme } from "../../../../../theme";

export const styles = {
  status: {
    margin: "auto",
    marginBottom: 25,
    width: "90%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  } as CSSProperties,
  price: {
    padding: 7,
    borderRadius: theme.borderRadius.l,
    backgroundColor: theme.colors.green,
  },
  meta: {
    marginRight: 10,
  },
  amount: {
    margin: "auto",
  },
  button: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 25,
  } as CSSProperties,
};
