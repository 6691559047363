import { useMediaQuery } from "@mui/material";
import { isTablet } from "../../theme";

export function useStyles() {
  const matchTablet = useMediaQuery(isTablet);
  const styles = {
    container: {
      margin: "auto",
    },
    pageContainer: {
      minHeight: "84vh",
      width: "100%",
    },
  };

  return {
    styles,
    matchTablet,
  };
}
