import { CSSProperties } from "react";
import { theme } from "../../../../../../theme";

export const styles: { [key: string]: CSSProperties } = {
  container: {
    marginTop: 5,
    padding: 16,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    cursor: "pointer",
    borderRadius: theme.borderRadius.m,
    backgroundColor: theme.colors.lightGrey,
  },
  picture: {
    height: 65,
    width: 95,
  },
  content: {
    width: "calc(100% - 95px)",
    padding: "0 10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 16,
    fontWeight: 550,
  },
  meta: {
    width: 100,
    fontSize: 12,
    fontWeight: 500,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  date: {
    marginTop: 5,
    fontSize: 11,
    color: theme.colors.primary,
  },
  containerHover: {
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
};
