import { User } from "./users";

export type Transaction = {
  buyer: string | User;
  seller: string | User;
  status: TransactionStatus;
  amount: number;
  _id: string;
  createdAt: string;
  updatedAt: string;
};

export enum TransactionStatus {
  PENDING = "PENDING",
  COMPLETE = "COMPLETE",
  CANCELLED = "CANCELLED",
  DISPUTED = "DISPUTED",
  EXPIRED = "EXPIRED",
}
