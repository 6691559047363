import { TokenUser } from "../../types/users";

/** Types */
export enum UsersTypes {
  SET_USER,
}

export type SetUser = {
  user: TokenUser | null;
};

export type Actions = SetUser | UsersTypes;
