import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import { useRootContext } from "../../../context/root";
import { ActionTypes } from "../../../context/root/actions";
import { ListItem as _ListItem } from "../../../types/common";
import { style } from "./styles";
import SignpostIcon from "@mui/icons-material/Signpost";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import HandshakeIcon from "@mui/icons-material/Handshake";
import { NavigateFunction, useNavigate } from "react-router-dom";

const OPEN_SIDE = "left";

const SIDESHEET_LIST: _ListItem[] = [
  { icon: <SignpostIcon />, value: "postings", label: "Posts" },
  { icon: <LocalOfferIcon />, value: "offers", label: "Offers" },
  { icon: <HandshakeIcon />, value: "transactions", label: "Transactions" },
];

export default function Sidesheet() {
  const { state, dispatch } = useRootContext();
  const navigate = useNavigate();

  return (
    <React.Fragment key={OPEN_SIDE}>
      <Drawer
        anchor={OPEN_SIDE}
        open={state.isSidesheetOpen}
        onClick={() =>
          dispatch({
            type: ActionTypes.TOGGLE_SIDESHEET,
            payload: {
              isSidesheetOpen: false,
            },
          })
        }
        onClose={() =>
          dispatch({
            type: ActionTypes.TOGGLE_SIDESHEET,
            payload: {
              isSidesheetOpen: false,
            },
          })
        }
      >
        {renderSidesheetList(navigate)}
      </Drawer>
    </React.Fragment>
  );
}

function renderSidesheetList(navigate: NavigateFunction) {
  return (
    <Box style={style.listItem} role="presentation">
      <List>
        {SIDESHEET_LIST.map((item) => (
          <ListItem onClick={() => navigate(`/${item.value}`)} key={item.value}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemButton>
              <ListItemText primary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
