import { useEffect, useState } from "react";
import { getLocations } from "../../../api/locations";
import { Location } from "../../../types/locations";

export const useFetchLocations = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [locations, setLocations] = useState<Location[]>([]);

  useEffect(() => {
    if (isLoading) return;
    const init = async () => {
      const { _locations } = await fetchLocations();
      setLocations(_locations);
    };
    init();
  }, []);

  const fetchLocations: () => Promise<{
    _locations: Location[];
  }> = async () => {
    try {
      setIsLoading(true);
      const response = await getLocations();

      setIsLoading(false);
      return {
        _locations: response,
      };
    } catch (error) {
      setIsLoading(false);
      return {
        _locations: [],
      };
    }
  };

  return {
    locations,
    isLoading,
  };
};
