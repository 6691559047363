import { CSSProperties } from "react";
import { theme } from "../../../../../theme";

export const styles = {
  heading: {
    color: theme.colors.secondary,
  },
  title: {
    textAlign: "center",
    marginTop: 25,
    marginBottom: 25,
  } as CSSProperties,
};
