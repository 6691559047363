import { CSSProperties } from "react";

export const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  } as CSSProperties,
  input: {
    margin: 15,
  },
  center: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  } as CSSProperties,
};
