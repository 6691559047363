import { ModelPayload, ToastPayload } from "../types/payload";

/** Types */
export enum ActionTypes {
  TOGGLE_SIDESHEET,
  TOGGLE_MODAL,
  SET_MODEL,
  TOGGLE_TOAST,
  SET_LOCATION,
}

/** Actions */
export type ToggleSidesheet = {
  isSidesheetOpen: boolean;
};

export type ToggleModal = {
  model: ModelPayload | null;
};

export type ToggleToast = {
  toast: ToastPayload | null;
};

export type SetLocation = {
  location: string;
};

export type Actions = ToggleSidesheet | ToggleModal | ToggleToast | SetLocation;
