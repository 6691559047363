import { useEffect, useState } from "react";
import { fetchCategories } from "../../../api/categories";
import { Category } from "../../../types/categories";

export const useCategories = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [categories, setCategories] = useState<Category[]>([]);

  useEffect(() => {
    (async function init() {
      getCategories();
    })();
  }, []);

  const getCategories = async () => {
    try {
      if (isLoading) return;

      setIsLoading(true);
      setCategories(await fetchCategories());
    } catch (err) {
      setError(err as Error);
      console.error(err);
    }
    setIsLoading(false);
  };

  return {
    error,
    isLoading,
    categories,
  };
};
