import { Post } from "./posts";
import { User } from "./users";

export type OfferPost = Post & {
  offer: Offer;
};

/**
 * Offer author is not populated
 * author id
 */
export type OfferRef = Offer & {
  author: string;
};

export type Offer = {
  amount: number;
  author: User;
  status: OfferStatus;
  _id: string;
  createdAt: string;
  updatedAt: string;
};

export type OfferResponse = {
  _id: string;
  post_id: string;
  amount: number;
  author: string;
  status: string;
};

export enum OfferStatus {
  PENDING = "PENDING",
  CANCELLED = "CANCELLED",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  EXPIRED = "EXPIRED",
}
