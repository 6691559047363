import * as React from "react";
import { Alert, Snackbar } from "@mui/material";
import { useRootContext } from "../../../context/root";
import { ActionTypes } from "../../../context/root/actions";
import { styles } from "./styles";
import { ToastSeverity } from "../types/toast-severity";

const HIDE_DURATION = 6000;

export default function ToastMessage() {
  const { state, dispatch } = useRootContext();

  const handleClose = () =>
    dispatch({
      type: ActionTypes.TOGGLE_TOAST,
      payload: {
        toast: {
          isToastOpen: false,
          severity: state.toast?.severity || ToastSeverity.INFO,
          message: "",
        },
      },
    });

  return (
    <Snackbar open={state.toast?.isToastOpen} autoHideDuration={HIDE_DURATION} onClose={handleClose}>
      <Alert onClose={handleClose} severity={state.toast?.severity} sx={styles.container} variant="filled">
        {state.toast?.message || ""}
      </Alert>
    </Snackbar>
  );
}
