import React from "react";
import { CircularProgress } from "@mui/material";
import { Post } from "../../../../types/posts";
import PostRow from "./components/PostRow";
import { styles } from "./styles";

interface PostsListProps {
  posts: Post[];
  isLoading: boolean;
}

function PostsList({ posts, isLoading }: PostsListProps) {
  return (
    <div style={styles.container}>
      {isLoading && !posts.length ? (
        <CircularProgress />
      ) : (
        <div>
          {posts.map((post) => (
            <PostRow key={post._id} post={post} />
          ))}
        </div>
      )}
    </div>
  );
}

export default React.memo(PostsList);
