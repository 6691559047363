import { CSSProperties } from "react";
import { theme } from "../../../../../theme";

export const styles = {
  container: {
    width: "100%",
    boxShadow: theme.shadow.card,
    borderRadius: theme.borderRadius.s,
    padding: 10,
    margin: 20,
  },
  header: {
    color: theme.colors.primary,
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 20,
    textAlign: "center",
  } as CSSProperties,
};
