import { OfferPost } from "../../types/offers";
import { Post } from "../../types/posts";

/** Types */
export enum PostsTypes {
  GET_USER_POSTS,
  SET_USER_POSTS,
  GET_USER_OFFERS_MADE,
  SET_USER_OFFERS_MADE,
  GET_USER_TRANSACTION,
  SET_USER_TRANSCATION,
}

export type UserPosts = {
  posts: Post[];
};

export type UserOffersSent = {
  offersSent: OfferPost[];
};

export type UserTransactions = {
  transactions: Post[];
};

export type Actions = UserPosts | UserOffersSent | UserTransactions;
