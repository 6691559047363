import { AxiosError } from "axios";
import { useState } from "react";
import { forgotPassword } from "../../../api/users";
import { User } from "../../../types/users";
import useToggleToast from "../../components/hooks/useToggleToast";
import { ToastSeverity } from "../../components/types/toast-severity";
import validate from "validate.js";
import { useNavigate } from "react-router-dom";

const emailValidationConstraints = {
  email: {
    presence: true,
    email: true,
  },
};

export const useForgotPassword = () => {
  const [result, setResult] = useState<User>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const { toggle } = useToggleToast();
  const navigate = useNavigate();

  const doForgotPassword = async (email: string) => {
    console.log(email);
    if (validate({ email }, emailValidationConstraints)) return toggle("Invalid email address", ToastSeverity.ERROR);

    try {
      if (isLoading) return;
      setIsLoading(true);
      console.log("HERE : ");
      const result = await forgotPassword({ email });
      setResult(result);
    } catch (err) {
      toggle((err as AxiosError<any>).response?.data.message, ToastSeverity.ERROR);
      setError(err as AxiosError);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };
  return {
    error,
    isLoading,
    doForgotPassword,
    result,
  };
};
