import { CSSProperties } from "react";
import { theme } from "../../../theme";

export const styles = {
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "0 16px",
  } as CSSProperties,
  postContainer: {
    width: "80%",
  },
  pagination: {
    padding: `${theme.padding.xl}px 0`,
  },
};
