import { useState } from "react";
import { declineOffer } from "../../../api/offers";
import { useUsersContext } from "../../../context/users";
import { ToastSeverity } from "../../components/types/toast-severity";
import useToggleToast from "../../components/hooks/useToggleToast";

export const useDeclineOffer = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const { state } = useUsersContext();
  const { toggle } = useToggleToast();

  const doDeclineOffer = async (postId: string, offerId: string, callback: Function) => {
    try {
      if (isLoading) return;
      if (!state.user?.sub) return;

      setIsLoading(true);
      await declineOffer(postId, offerId);
      handleOfferSuccess();
      callback && callback();
    } catch (err) {
      handleOfferFailure((err as Error).message);
      setError(err as Error);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOfferSuccess = () => toggle("Offer Accepted", ToastSeverity.SUCCESS);
  const handleOfferFailure = (message: string) => toggle(message, ToastSeverity.ERROR);

  return {
    error,
    isLoading,
    doDeclineOffer,
  };
};
