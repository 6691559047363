import { CSSProperties } from "react";
import { theme } from "../../../theme";

export const styles = {
  modal: {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    backgroundColor: theme.colors.white,
    padding: 20,
    boxShadow: theme.shadow.card,
    border: `5px solid ${theme.colors.primary}`,
  } as CSSProperties,
};
