import { SxProps, Theme } from "@mui/material";
import { theme } from "../../../theme";

interface SettingsStyles {
  container: SxProps<Theme>;
  tabs: SxProps<Theme>;
  tab: SxProps<Theme>;
  content: SxProps<Theme>;
  tabPanel: {
    wrapper: React.CSSProperties;
    content: SxProps<Theme>;
  };
}

export const styles: SettingsStyles = {
  container: {
    width: "100%",
    display: "flex",
  },
  tabs: {
    maxWidth: "15%",
    borderRight: 1,
    borderColor: "divider",
    "& .MuiTabs-indicator": {
      backgroundColor: "primary.main",
      left: 0,
      width: 3,
    },
  },
  tab: {
    alignItems: "flex-start",
    textAlign: "left",
    padding: "12px 16px",
    minWidth: "unset",
    color: theme.colors.primary,
    "&.Mui-selected": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
      color: theme.colors.secondary,
    },
  },
  content: {
    flexGrow: 1,
    width: "90%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tabPanel: {
    wrapper: {
      width: "100%",
      height: "100%",
    },
    content: {
      p: 3,
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
};
