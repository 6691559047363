import React, { useEffect, useState } from "react";
import { PER_PAGE } from "../../../config";
import PageCard from "../../components/PageCard";
import SectionHeader from "../../components/SectionHeader";
import { styles } from "./styles";
import { useUsersContext } from "../../../context/users";
import PostsList from "../components/PostsList";
import { usePostsContext } from "../../../context/posts";
import { Pagination } from "@mui/material";

export default function UserPosts() {
  const [page, setPage] = useState<number>(0);
  const {
    state: { user },
  } = useUsersContext();
  const { state, refetchPosts, isLoading, numOfPages } = usePostsContext();

  useEffect(() => {
    if (user) {
      refetchPosts({
        page,
        limit: PER_PAGE,
        user: user?.sub || "",
      });
    }
  }, [page, user]);

  return (
    <PageCard>
      <div style={styles.container}>
        <SectionHeader text="My Posts" />
        <PostsList posts={state.posts} isLoading={isLoading} />
        <Pagination count={numOfPages} page={page + 1} onChange={(e, p) => setPage(p - 1)} />
      </div>
    </PageCard>
  );
}
