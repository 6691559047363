import { CSSProperties } from "react";
import { theme } from "../../../theme";
import { SxProps } from "@mui/material";

export const styles = {
  container: {
    mt: 6,
    mb: 3,
  } as SxProps,
  paper: {
    p: 3,
  } as SxProps,
  title: {
    color: theme.colors.primary,
  } as SxProps,
  description: {
    mb: 2,
    color: theme.colors.secondary,
  } as SxProps,
  textField: {
    "& .MuiInputLabel-root": {
      color: theme.colors.primary,
    },
  } as SxProps,
  emailIcon: {
    mr: 1,
    color: "action.active",
    fontSize: "1.2rem",
  } as SxProps,
  submitButton: {
    mt: 2,
    mb: 1,
    bgcolor: theme.colors.secondary,
    "&:hover": {
      bgcolor: theme.colors.secondary,
    },
  } as SxProps,
  loading: {
    padding: theme.padding.m,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  } as CSSProperties,
  box: {
    mt: 6,
    mb: 3,
  } as SxProps,
  formContainer: {
    width: "100%",
  } as CSSProperties,
  inputAdornment: {
    mr: 1,
    color: "action.active",
    fontSize: "1.2rem",
  } as SxProps,
  titleTypography: {
    color: theme.colors.primary,
  },
  descriptionTypography: {
    color: "text.secondary",
  },
  textFieldStyle: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.colors.primary,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.colors.primary,
    },
  },
};
