import { CSSProperties } from "react";

export const styles = {
  posts: {
    width: "85%",
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  } as CSSProperties,
  loading: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 250,
    marginBottom: 75,
  } as CSSProperties,
  pagination: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "auto",
  } as CSSProperties,
};
