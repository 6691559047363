import { AxiosError } from "axios";
import { useState } from "react";
import { withdrawFunds } from "../../../api/payments";
import useToggleToast from "../../components/hooks/useToggleToast";
import { ToastSeverity } from "../../components/types/toast-severity";

export const useWithdrawFunds = () => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const { toggle } = useToggleToast();

  const doWithdrawFunds = async (address: string) => {
    if (!address) return;
    try {
      if (isLoading) return;
      setIsLoading(true);

      await withdrawFunds(address);
      setIsSuccess(true);
    } catch (err) {
      setError(err as Error);
      console.error(err);
      toggle((err as AxiosError<any>).response?.data.message, ToastSeverity.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isSuccess,
    error,
    isLoading,
    doWithdrawFunds,
  };
};
