import { AxiosError } from "axios";
import { useState } from "react";
import { createPost } from "../../../api/posts";
import { useUsersContext } from "../../../context/users";
import useToggleToast from "../../components/hooks/useToggleToast";
import { ToastSeverity } from "../../components/types/toast-severity";

export const useCreatePost = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const { state } = useUsersContext();
  const { toggle } = useToggleToast();

  const doCreatePost = async (post: FormData, callback?: Function) => {
    try {
      if (isLoading) return;
      if (!state.user) return;
      setIsLoading(true);
      await createPost(post);
      callback && callback();
      toggle("Post Created", ToastSeverity.SUCCESS);
    } catch (err) {
      toggle((err as AxiosError<any>).response?.data.message, ToastSeverity.ERROR);
      setError(err as Error);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };
  return {
    error,
    isLoading,
    doCreatePost,
  };
};
