import { theme } from "../../../theme";

export const styles = {
  heading: {
    fontSize: 20,
    fontWeight: 700,
    marginBottom: 20,
    textDecoration: "underline",
    color: theme.colors.secondary,
  },
};
