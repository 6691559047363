import { theme } from "../../../theme";
import { ButtonVariants } from "../types/button-variants";

interface UseStylesProps {
  variant: ButtonVariants;
}

export function useStyles({ variant }: UseStylesProps) {
  const styles = {
    button: {
      cursor: "pointer",
      minWidth: 100,
      maxHeight: 30,
      borderRadius: 15,
      background:
        variant === "primary" ? theme.colors.charcoal : theme.colors.white,
      color: variant === "primary" ? theme.colors.white : theme.colors.charcoal,
      border: "none",
      boxShadow: theme.shadow.card,
      padding: "7px 1px 7px 1px",
      fontWeight: 700,
    },
  };

  return {
    styles,
  };
}
