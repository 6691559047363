import { PostsAction } from "../types/action";
import { PostsState } from "../types/state";
import { PostsTypes, UserPosts, UserOffersSent, UserTransactions } from "./actions";

export function postsReducer(state: PostsState, action: PostsAction): PostsState {
  switch (action.type) {
    case PostsTypes.SET_USER_POSTS:
      return {
        ...state,
        posts: (action.payload as UserPosts).posts,
      };
    case PostsTypes.SET_USER_OFFERS_MADE:
      return {
        ...state,
        offersSent: (action.payload as UserOffersSent).offersSent,
      };
    case PostsTypes.SET_USER_TRANSCATION:
      return {
        ...state,
        transactions: (action.payload as UserTransactions).transactions,
      };
    default:
      return {
        ...state,
      };
  }
}
