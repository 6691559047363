import { CSSProperties } from "react";

export const styles = {
  container: {
    width: "100%",
  },
  section: {
    width: "100%",
  },
  tabs: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  } as CSSProperties,
  select: {
    width: 125,
  },
  selectContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "right",
  } as CSSProperties,
  table: {
    padding: 30,
  },
  loadingIcon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  } as CSSProperties,
};
