import React from "react";
import { Outlet } from "react-router-dom";
import Modal from "../../components/Modal";
import ToastMessage from "../../components/Toast";

export default function Singles() {
  return (
    <div>
      <Outlet />
      <Modal />
      <ToastMessage />
    </div>
  );
}
