import { UserRegister } from "../modules/Users/types/user";
import { ForgotPassword, LoginParams, Message, MessageDocument, ResetPassword, User } from "../types/users";
import axios from "../utils/axios";

export const login = async (request: LoginParams) => {
  const response = await axios.post<{ access_token: string }>(`auth/token`, request);
  return response.data;
};

export const register = async (request: UserRegister) => {
  const response = await axios.post<{ user: User }>(`user/create`, request);
  return response.data;
};

export const activate = async (code: string) => {
  const response = await axios.post<{ success: boolean }>(`user/activate/${code}`);
  return response.data;
};

export const resetPassword = async (request: ResetPassword) => {
  const response = await axios.post<User>(`auth/user/reset-password`, request);
  return response.data;
};

export const setNewPassword = async (request: { password: string; hash: string }) => {
  const response = await axios.post<{ success: boolean }>(`user/new-password`, request);
  return response.data;
};

export const forgotPassword = async (request: ForgotPassword) => {
  const response = await axios.post<User>(`user/forgot-password`, request);
  return response.data;
};

export const message = async (request: Message) => {
  const response = await axios.post<MessageDocument>(`auth/user/message`, request);
  return response.data;
};
