import { OfferRow } from "../modules/Offers/types/table-models";
import { Post } from "../types/posts";

export function capitalizeFirstLetter(str: string) {
  const words = str.split(" ");
  return words.map((word) => word.slice(0, 1).toUpperCase() + word.slice(1, str.length).toLowerCase()).join(" ");
}

export function mapPostToTableRow(rows: Post[]): OfferRow[] {
  let offers = [];

  for (let row of rows) {
    for (let offer of row.offers) {
      offers.push({
        ...row,
        offer,
        status: offer.status,
      });
    }
  }

  return offers;
}

export function formatBTCAmount(amount: number) {
  return `${amount.toFixed(8)} BTC`;
}

export function formatCurrencyAmount(amount: number, currency: string) {
  return `$${amount.toFixed(2)} ${currency}`;
}

/**
 * Convert dollar amount to BTC
 *
 * @param amount cost in currency
 * @param oneBTCToCurrency value of 1 btc in currency
 * @returns BTC value
 */
export function amountToBTC(amount: number, oneBTCToCurrency: number | undefined) {
  if (oneBTCToCurrency) return `${formatBTCAmount(amount / oneBTCToCurrency)}`;

  return undefined;
}

export const handleListenEnter = (e: React.KeyboardEvent, f: Function) => {
  if (e.key === "Enter") {
    f();
  }
};
