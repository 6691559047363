import { CSSProperties } from "react";
import { theme } from "../../../../../theme";

export const styles: { [key: string]: CSSProperties } = {
  container: {
    width: "100%",
    height: "100%",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    textAlign: "left",
    color: theme.colors.white,
    marginBottom: 16,
    fontSize: 30,
  },
  fields: {
    height: 48,
    width: "40%",
    border: "none",
    borderRadius: theme.borderRadius.s,
    marginBottom: 28,
    background: theme.colors.white,
  },
  button: {
    backgroundColor: theme.colors.secondary,
    width: "40%",
    height: 45,
  },
  labelContainer: {
    width: "40%",
    textAlign: "left",
    marginBottom: 8,
    color: theme.colors.secondary,
  },
  heading: {
    width: "40%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  text: {
    textAlign: "left",
    color: theme.colors.white,
    marginBottom: 16,
  },
  footer: {
    marginTop: 16,
    fontWeight: 500,
    color: theme.colors.white,
  },
  forgotPassword: {
    width: "40%",
    textAlign: "left",
    marginTop: 8,
    marginBottom: 16,
  },
};
