import { Post } from "../types/posts";
import axios from "../utils/axios";
/**
 * Fetch transactions
 * @param query
 * @returns
 */
export const getTransactions = async () => {
  const response = await axios.get<Post[]>(`auth/transactions`);
  return response.data;
};

export const completeTransaction = async (postId: string, transactionId: string) => {
  const response = await axios.post<Post>(`auth/transactions/post/${postId}/transaction/${transactionId}/complete`);
  return response.data;
};

export const cancelTransaction = async (postId: string, transactionId: string) => {
  console.log(postId, transactionId);
  const response = await axios.post<Post>(`auth/transactions/post/${postId}/transaction/${transactionId}/cancel`);
  return response.data;
};
