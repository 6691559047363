import { Button, CircularProgress, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useState } from "react";
import { RefetchPost } from "../../../../../context/types/payload";
import { Post } from "../../../../../types/posts";
import { useEditPost } from "../../../../Posts/hooks/useEditPost";
import useToggleModal from "../../../hooks/useToggleModal";
import SectionHeader from "../../../SectionHeader";
import { styles } from "./styles";

interface EditPostProps {
  post: Post;
  refetchPost: RefetchPost;
}

const CONDITIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export default function EditPost({ post, refetchPost }: EditPostProps) {
  const [_post, setPost] = useState<Post>(post);

  const { isLoading, doEditPost } = useEditPost();
  const { toggle } = useToggleModal();

  const onSubmit = () => {
    refetchPost(post._id);
    toggle(null);
  };

  return (
    <div>
      <SectionHeader text="Edit Post" />
      <div style={styles.container}>
        <TextField style={styles.input} value={_post.title} onChange={(e) => setPost({ ..._post, title: e.target.value })} size="small" />
        <TextField
          style={styles.input}
          value={_post.description}
          onChange={(e) => setPost({ ..._post, description: e.target.value })}
          size="small"
          rows={4}
          multiline
        />
        <TextField
          style={styles.input}
          size="small"
          id="price"
          label="Price"
          variant="outlined"
          value={_post.price}
          onChange={(e) =>
            setPost({
              ..._post,
              price: parseInt(e.target.value),
            })
          }
        />
        <InputLabel>Condition</InputLabel>
        <Select
          style={styles.input}
          size="small"
          label="Condition"
          value={_post.condition}
          onChange={(e) => setPost({ ..._post, condition: Number(e.target.value) })}
        >
          {CONDITIONS.map((val) => (
            <MenuItem value={val}>{val}</MenuItem>
          ))}
        </Select>

        {isLoading ? (
          <div style={styles.center}>
            <CircularProgress />
          </div>
        ) : (
          <Button onClick={() => doEditPost(_post, onSubmit)} style={styles.input} variant="contained" size="small">
            Edit Post
          </Button>
        )}
      </div>
    </div>
  );
}
