import { AxiosError } from "axios";
import React, { useState } from "react";
import { resetPassword } from "../../../api/users";
import { User } from "../../../types/users";
import useToggleToast from "../../components/hooks/useToggleToast";
import { ToastSeverity } from "../../components/types/toast-severity";

export const useResetPassword = () => {
  const [result, setResult] = useState<User>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const { toggle } = useToggleToast();

  const doResetUser = async (oldPassword: string, newPassword: string) => {
    try {
      if (!oldPassword || !newPassword) return;
      if (isLoading) return;

      setIsLoading(true);

      const result = await resetPassword({
        oldPassword,
        newPassword,
      });
      setResult(result);
    } catch (err) {
      toggle((err as AxiosError<any>).response?.data.message, ToastSeverity.ERROR);
      setError(err as AxiosError);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };
  return {
    error,
    isLoading,
    doResetUser,
    result,
  };
};
