import { CSSProperties } from "react";
import { theme } from "../../../theme";

export const styles = {
  field: {
    width: "58%",
    marginTop: 25,
    backgroundColor: "white",
    borderRadius: theme.borderRadius.s,
  },
  buttonContainer: {
    minWidth: "100%",
    textAlign: "center",
    padding: theme.padding.xl,
  } as CSSProperties,
  button: {
    width: "33%",
  },
  subfields: {
    width: "49%",
    marginTop: 25,
    backgroundColor: theme.colors.white,
  },
  image: {
    padding: "10px 0px",
  },
  inputLabelProps: {
    // renamed from inputLabelProps
    color: theme.colors.primary,
    fontWeight: 600,
  },
  selectLabelProps: {
    color: theme.colors.primary,
    fontWeight: 600,
  },
};
