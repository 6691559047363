import { CSSProperties } from "react";
import { theme } from "../../../theme";

export const useStyles = () => {
  return {
    styles: {
      container: {
        margin: "auto",
        marginTop: 25,
        marginBottom: 25,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: 25,
        boxShadow: theme.shadow.form,
      } as CSSProperties,
    },
  };
};
