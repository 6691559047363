import { useState } from "react";
import { PostStatus } from "../../../types/posts";
import CategoryBar from "../../Categories/CategoryBar";
import Banner from "../../Common/Banner";
import PaginatedPosts from "../components/PaginatedPosts";
import { styles } from "./style";
import { Link } from "react-router-dom";
import { theme } from "../../../theme";
import { useRootContext } from "../../../context/root";

const NUM_POSTS = 4;

const Feature = () => {
  const {
    state: { location },
  } = useRootContext();
  const [filter] = useState({
    page: 0,
    limit: NUM_POSTS, // Feature page should just show 4 posts
    status: PostStatus.OPEN,
    location,
  });

  return (
    <>
      <CategoryBar />
      <Banner
        title={
          <>
            <Link style={{ color: theme.colors.white, marginRight: 8 }} to={"/browse"}>
              Browse
            </Link>
            Items For Sale, List Your Items, Earn Crypto.
          </>
        }
      />
      {location && (
        <div style={styles.posts}>
          <PaginatedPosts filter={filter} />
        </div>
      )}
    </>
  );
};
export default Feature;
