import React, { CSSProperties } from "react";
import PageCard from "../modules/components/PageCard";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import { theme } from "../theme";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 20,
  } as CSSProperties,
  icon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: 20,
  } as CSSProperties,
};

export default function NotFound() {
  return (
    <PageCard>
      <div style={styles.container}>
        Page not found!
        <div style={styles.icon}>
          <GppMaybeIcon style={{ color: theme.colors.red, fontSize: 80 }} />
        </div>
      </div>
    </PageCard>
  );
}
