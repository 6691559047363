import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Sidesheet from "../../Common/Sidesheet";
import { useStyles } from "../styles";

interface ProtectedRouteProps {
  isAuthenticated: string | null;
}

export default function ProtectedRoute({ isAuthenticated }: ProtectedRouteProps) {
  const navigate = useNavigate();
  const { styles } = useStyles();

  useEffect(() => {
    if (!isAuthenticated) navigate("/login");
  });

  return (
    <div style={styles.container}>
      <Outlet />
      <Sidesheet />
    </div>
  );
}
