import React, { useState } from "react";
import { styles } from "./styles";
import { useFetchLocations } from "../../../../Locations/hooks/useFetchLocations";
import useToggleModal from "../../../hooks/useToggleModal";
import { LocalStorage } from "../../../../../utils/local-storage";
import { LocalStorageKeys } from "../../../../../types/common";
import { useRootContext } from "../../../../../context/root";
import { ActionTypes } from "../../../../../context/root/actions";

export default function Location() {
  const { locations, isLoading } = useFetchLocations();
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const { toggle } = useToggleModal();
  const { dispatch } = useRootContext();

  const handleClick = (location: string) => {
    LocalStorage.set(LocalStorageKeys.LOCATION, location);
    dispatch({ type: ActionTypes.SET_LOCATION, payload: { location } });
    toggle(null);
  };

  return (
    <div>
      <p style={styles.title}>Select a location</p>
      {isLoading ? (
        "Loading..."
      ) : (
        <ul style={styles.list}>
          {locations.map((location, index) => (
            <li
              onClick={() => handleClick(location.city)}
              key={location._id}
              style={{
                ...styles.listItem,
                ...(hoveredIndex === index ? styles.listItemHover : {}),
              }}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <p style={styles.button}>{location.city}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
